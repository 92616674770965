import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import { Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { AuthContext } from "core/providers/AuthProvider";
import { authedUserQuery } from "core/graphql/queries";
import { Spinner } from "components/util/Spinner";
import { ManagerNavigation } from "../ManagerNavigation/ManagerNavigation";
import { AppLogo } from "./AppLogo";
import { auth } from "core/services/firebase";
import ErrorBoundary from "components/util/ErrorBoundary";

import classes from "./managerLayout.module.scss";

const { Content, Sider } = Layout;

const ManagerLayout = ({ component: Component, ...rest }) => {
  const {
    currentUser,
    loading: authLoading,
    setLoading: setUserLoading
  } = useContext(AuthContext);

  const { loading: userLoading, data } = useQuery(authedUserQuery, {
    skip: !currentUser
  });

  // Check authenticated
  if (authLoading) {
    return <Spinner />;
  }
  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  // Check if logged in user is manager
  if (userLoading) {
    return <Spinner />;
  }
  if (!data.authedUser || !data.authedUser.manager) {
    // If client account tries to sign in / log them out
    if (data.authedUser.client) {
      auth.signOut();
      return <Spinner />;
    }
    return <Redirect to="/" />;
  }

  const { manager } = data.authedUser;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            className={classes.managerLayoutSider}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0
            }}
          >
            <AppLogo />
            <ManagerNavigation
              manager={manager}
              setUserLoading={setUserLoading}
            />
          </Sider>
          <Layout className={classes.managerLayout} style={{ marginLeft: 200 }}>
            <Content style={{ paddingBottom: 30 }}>
              <ErrorBoundary>
                <Component {...matchProps} manager={manager} />
              </ErrorBoundary>
            </Content>
          </Layout>
        </Layout>
      )}
    />
  );
};

ManagerLayout.propTypes = {
  component: PropTypes.func.isRequired
};

export { ManagerLayout };
