const getAddressFromComponents = components => {
  let streetNumber = components.find(c => c.types.includes("street_number"));
  streetNumber = streetNumber && streetNumber.long_name;
  let street = components.find(c => c.types.includes("route"));
  street = street && street.long_name;
  let city = components.find(c => c.types.includes("locality"));
  city = city && city.long_name;
  let state = components.find(c =>
    c.types.includes("administrative_area_level_1")
  );
  state = state && state.long_name;
  let postalCode = components.find(c => c.types.includes("postal_code"));
  postalCode = postalCode && postalCode.long_name;
  const streetAddress = `${streetNumber} ${street}`.trim();

  return {
    street: streetAddress,
    city,
    state,
    postalCode
  };
};

export { getAddressFromComponents };
