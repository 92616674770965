import React from "react";
import { Col, Space } from "antd";
import PropTypes from "prop-types";

import { ClientDetailMonthlyProgress } from "./ClientDetailMonthlyProgress";
import { ClientDetailCalendar } from "./ClientDetailCalendar";
import { ClientDetailNotes } from "./ClientDetailNotes";

const ClientDetailContent = ({ client }) => {
  return (
    <Col xl={18} lg={16}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <ClientDetailMonthlyProgress client={client} />
        <ClientDetailCalendar client={client} />
        <ClientDetailNotes clientId={Number(client.id)} />
      </Space>
    </Col>
  );
};

ClientDetailContent.propTypes = {
  client: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
};

export { ClientDetailContent };
