import React from "react";
import { Row } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { ClientsListRowClient } from "./ClientsListRowClient";
import { ClientsListRowInfo } from "./ClientsListRowInfo";
import { ClientsListRowActions } from "./ClientsListRowActions";

import classes from "./clientsList.module.scss";

const ClientsListRow = ({ client }) => {
  return (
    <Link to={`/client/${client.id}`}>
      <Row className={classes.clientsListRowContainer}>
        <ClientsListRowClient client={client} />
        <ClientsListRowInfo client={client} />
        <ClientsListRowActions client={client} />
      </Row>
    </Link>
  );
};

ClientsListRow.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientsListRow };
