import React, { useState } from "react";
import { Form, Input, Button, Anchor } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { auth } from "core/services/firebase";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const onFinish = async ({ email }) => {
    setLoading(true);
    await auth.sendPasswordResetEmail(email);
    setSuccess(true);
    setLoading(false);
  };
  return (
    <Form name="forgotpass" layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Must be valid email" }
        ]}
      >
        <Input size="large" placeholder="Email Address" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          disabled={success}
          icon={success ? <CheckOutlined /> : null}
          block
        >
          {success ? "Email sent" : "Request password reset"}
        </Button>
        <div style={{ marginTop: ".5rem" }}>
          <Anchor style={{ float: "right" }}>
            <Link to="/login">Back to login </Link>
          </Anchor>
        </div>
      </Form.Item>
    </Form>
  );
};

export { ForgotPassword };
