import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, message, InputNumber, Switch } from "antd";

const layout = {
  labelCol: { xl: { span: 6 }, lg: { span: 8 } },
  wrapperCol: { xl: { span: 8 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xl: { offset: 6, span: 8 }, lg: { offset: 8, span: 16 } }
};

const EditClientMeetingReminderForm = ({ client, updateClient }) => {
  const [reminderForm] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [isEnabled, setisEnabled] = useState(client.attendanceReminder);
  const editInfoInitialValues = {
    attendanceReminder: client.attendanceReminder,
    attendanceReminderDays: client.attendanceReminderDays
  };

  const onSubmit = async values => {
    const variables = {
      clientId: client.id,
      durationDays: client.durationDays,
      reqMeetings: client.reqMeetings,
      reqMeetingsFreq: client.reqMeetingsFreq,
      probationId: client.probationId,
      status: client.status,
      attendanceReminder: values.attendanceReminder,
      attendanceReminderDays: values.attendanceReminder
        ? values.attendanceReminderDays
        : 0
    };

    try {
      setSubmitting(true);
      await updateClient({ variables });
      message.success("Meeting reminder updated");
      setSubmitting(false);
    } catch (e) {
      console.error(e);
      message.error("Oops, there was a problem");
      setSubmitting(false);
    }
  };

  const onReminderChanged = value => {
    setisEnabled(value);
  };

  return (
    <Card title="Missed Meeting Reminder" style={{ marginBottom: 16 }}>
      <Form
        {...layout}
        form={reminderForm}
        name="clientMeetingReminder"
        onFinish={onSubmit}
        initialValues={editInfoInitialValues}
      >
        <Form.Item
          label="Enable meeting reminder"
          name="attendanceReminder"
          valuePropName="checked"
        >
          <Switch onChange={onReminderChanged} />
        </Form.Item>
        <Form.Item
          name="attendanceReminderDays"
          label="Remind after X days"
          style={{ display: !isEnabled ? "none" : "" }}
          rules={[
            {
              required: isEnabled,
              message: "Please set a reminder duration"
            },
            { type: "number", message: "Must be valid number" }
          ]}
        >
          <InputNumber min={1} placeholder="e.g. 3" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={submitting} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

EditClientMeetingReminderForm.propTypes = {
  client: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired
};

export { EditClientMeetingReminderForm };
