import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Form,
  Row,
  Input,
  Button,
  Divider,
  Select,
  Typography
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { LockOutlined } from "@ant-design/icons";

import { STATES } from "core/constants/states";
import { mapInputToCreateClientDto } from "./clientInviteForm.utils";

const { useForm } = Form;

const stateOptions = Object.keys(STATES).map(abbr => ({
  label: STATES[abbr],
  value: abbr
}));

const ClientInviteForm = ({
  inviteData,
  onSubmitFn,
  isSubmitting,
  errorMessage
}) => {
  const [inviteForm] = useForm();
  const onSubmit = useCallback(
    async values => {
      const input = { ...inviteData, ...values };
      const dto = mapInputToCreateClientDto(input);
      onSubmitFn(dto);
    },
    [inviteData, onSubmitFn]
  );

  return (
    <Form
      layout="vertical"
      name="meetingForm"
      onFinish={onSubmit}
      initialValues={inviteData}
      form={inviteForm}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            label="First name"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <Input disabled placeholder="John" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label="Last name"
            disabled
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <Input disabled placeholder="Johnson" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Must be valid email" }
            ]}
            name="email"
            label="Email"
          >
            <Input disabled placeholder="example@example.com" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[{ required: true, message: "Phone number is required" }]}
          >
            <MaskedInput disabled mask="(111) 111-1111" />
          </Form.Item>
        </Col>
      </Row>
      {inviteData.probationId && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="probationId" label="Program ID number">
              <Input disabled placeholder="000-0000000" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Choose a password"
            name="password"
            rules={[
              { required: true, message: "Password is required" },
              { min: 6, message: "Password must be at least 6 characters" }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              validateTrigger="onBlur"
              size="large"
              placeholder="Password"
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="streetAddress"
            label="Street address"
            rules={[{ required: true, message: "Street address required" }]}
          >
            <Input placeholder="100 main st. Apt 2" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: "City required" }]}
          >
            <Input placeholder="ex. Boston" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "State is required"
              }
            ]}
          >
            <Select options={stateOptions} placeholder="Choose..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="postalCode"
            label="Postal code"
            rules={[
              { required: true, message: "Postal code required" },
              {
                pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
                message: "Valid postal code required"
              }
            ]}
          >
            <Input placeholder="ex. 02210" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              Accept invite
            </Button>
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Typography.Text type="danger">{errorMessage}</Typography.Text>
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

ClientInviteForm.propTypes = {
  inviteData: PropTypes.object.isRequired,
  onSubmitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export { ClientInviteForm };
