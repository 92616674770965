import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Map from "google-map-react";
import { Avatar, Card, Col, Row, Space, Tag, Typography } from "antd";

import {
  getAttendanceReasonMsg,
  getBioVerifyType
} from "core/constants/attendance";
import {
  drawAttendanceOnMap,
  getBatteryPercentage
} from "./attendanceTable.utils";

const { Text, Paragraph } = Typography;
const size = { height: "350px", width: "100%" };

const BioData = ({ verification }) => {
  if (verification.biometricVerificationType) {
    return (
      <>
        {!verification.photoUrl && (
          <Text>
            {getBioVerifyType(verification.biometricVerificationType)}
          </Text>
        )}
        {verification.photoUrl && (
          <Avatar size={60} src={verification.photoUrl} />
        )}
      </>
    );
  } else {
    return <Text>No biometric used</Text>;
  }
};

const AttendanceTableRowPanel = ({ record }) => {
  const invalidReasons = useMemo(
    () => record.validation.invalidReasons.map(getAttendanceReasonMsg),
    [record.validation.invalidReasons]
  );

  const onMapsLoaded = ({ map, maps }) => {
    drawAttendanceOnMap(map, maps, record);
  };

  return (
    <Card size="small" title="Verification Info">
      <Row gutter={16}>
        <Col span={6}>
          <Space direction="vertical">
            <div style={{ marginBottom: "1rem" }}>
              <Text style={{ display: "block" }} strong>
                Check-in biometric
              </Text>
              <BioData verification={record.checkInVerification} />
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Text style={{ display: "block" }} strong>
                Battery level at check-in
              </Text>
              <Text>{getBatteryPercentage(record.checkInBatteryLevel)}</Text>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Text style={{ display: "block" }} strong>
                Check-out biometric
              </Text>
              <BioData verification={record.checkOutVerification} />
            </div>
            {!!invalidReasons.length && (
              <div style={{ marginBottom: "1rem" }}>
                <Text style={{ display: "block" }} strong>
                  Validation Warnings
                </Text>
                {invalidReasons.map((r, i) => (
                  <Tag style={{ marginBottom: 5 }} key={i}>
                    {r}
                  </Tag>
                ))}
              </div>
            )}
            <div style={{ marginBottom: "1rem" }}>
              <Text style={{ display: "block" }} strong>
                Notes from client
              </Text>
              <Paragraph>
                {record.notes || "No notes for this record"}
              </Paragraph>
            </div>
          </Space>
        </Col>
        <Col span={18} style={{ borderLeft: "1px solid #f0f0f0" }}>
          <div style={size}>
            <Map
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
              }}
              options={{ draggable: false }}
              defaultCenter={record.meeting.location}
              defaultZoom={11}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={onMapsLoaded}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

AttendanceTableRowPanel.propTypes = {
  record: PropTypes.object.isRequired
};

export { AttendanceTableRowPanel };
