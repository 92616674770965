import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Avatar, List } from "antd";

import { Spinner } from "components/util/Spinner";

import styles from "./messagesList.module.scss";

const MessageListItem = ({
  localChats,
  clients,
  conversation,
  isSelected,
  onSelect
}) => {
  const clientConvo = localChats.find(
    lc => lc.conversationSid === conversation.sid
  );
  const client = clients.find(
    c => parseInt(c.id, 10) === clientConvo?.clientId
  );

  if (!client) return <></>;
  return (
    <List.Item
      className={cn(styles.listItem, {
        [styles.selected]: isSelected
      })}
      key={conversation.sid}
      onClick={() => onSelect(client.id)}
    >
      {!client && <Spinner />}
      {client && (
        <List.Item.Meta
          avatar={<Avatar src={client.photoUrl} />}
          title={`${client.firstName} ${client.lastName}`}
        />
      )}
    </List.Item>
  );
};

MessageListItem.propTypes = {
  localChats: PropTypes.arrayOf(PropTypes.object).isRequired,
  clients: PropTypes.arrayOf(PropTypes.object).isRequired,
  conversation: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export { MessageListItem };
