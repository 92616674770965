import React from "react";
import { Row, Col, Statistic } from "antd";
import PropTypes from "prop-types";

import { AttendanceService, ATTENDANCE_STATUS } from "core/services/attendance";

import classes from "./clientsList.module.scss";

const ClientsListRowInfo = ({ client }) => {
  const counts = AttendanceService.getStatusCounts(
    client.currentMonthAttendance
  );
  return (
    <Col flex="4" className={classes.clientsListRowClientInfo}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }} justify="end">
        <Col>
          <Statistic title="Required" value={client.meetingsRequiredPerMonth} />
        </Col>
        <Col>
          <Statistic
            title="Verified"
            value={counts[ATTENDANCE_STATUS.VERIFIED]}
          />
        </Col>
        <Col>
          <Statistic
            title="Pending"
            value={counts[ATTENDANCE_STATUS.PENDING]}
          />
        </Col>
        <Col>
          <Statistic
            title="Unverified"
            value={counts[ATTENDANCE_STATUS.UNVERIFIED]}
          />
        </Col>
      </Row>
    </Col>
  );
};

ClientsListRowInfo.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientsListRowInfo };
