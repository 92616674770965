import React from "react";
import PropTypes from "prop-types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import * as moment from "moment-timezone";

import {
  getAttendanceReasonMsg,
  getBioVerifyType
} from "core/constants/attendance";

const styles = StyleSheet.create({
  table: {
    marginHorizontal: 20,
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: { display: "flex", flexDirection: "row" },
  tableCol: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: { flex: 1, margin: "auto", marginTop: 5, fontSize: 10 },
  header: {
    backgroundColor: "#dadada"
  }
});

const getInOutTime = (checkIn, checkOut) => {
  const timeIn = checkIn ? moment(checkIn).format("h:mma") : "N/A";
  const timeOut = checkOut ? moment(checkOut).format("h:mma") : "N/A";
  return `${timeIn} - ${timeOut}`;
};

const ClientReportAttendanceTable = ({ attendance }) => {
  return (
    <View style={styles.table}>
      {/* TableHeader */}
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Meeting</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Meeting time</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>In meeting</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Status</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Validation Warnings</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Bio in</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Bio out</Text>
        </View>
        <View style={[styles.tableCol, styles.header]}>
          <Text style={styles.tableCell}>Online</Text>
        </View>
      </View>
      {/* TableContent */}
      {attendance.map((att, i) => {
        const invalidReasons = att.validation.invalidReasons.map(
          getAttendanceReasonMsg
        );
        return (
          <View key={i} style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{att.meeting.name}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(att.meetingStartTime).format("MM/DD/YYYY h:mma")}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getInOutTime(att.checkIn, att.checkOut)}
              </Text>
            </View>

            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{att.validation.status}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {invalidReasons.length
                  ? invalidReasons.map((r, i) =>
                      i === invalidReasons.length - 1 ? r : `${r}, `
                    )
                  : "--"}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getBioVerifyType(
                  att.checkInVerification.biometricVerificationType
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getBioVerifyType(
                  att.checkOutVerification.biometricVerificationType
                )}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {att.isVirtual ? "Yes" : "No"}
              </Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

ClientReportAttendanceTable.propTypes = {
  attendance: PropTypes.object.isRequired
};

export { ClientReportAttendanceTable };
