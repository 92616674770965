import React from "react";
import { Card } from "antd";

import { ForgotPassword } from "./ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <Card title="Forgot password" style={{ borderRadius: ".5rem" }}>
      <ForgotPassword />
    </Card>
  );
};

export { ForgotPasswordPage };
