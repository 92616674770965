import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Col, Card, message } from "antd";

import { Page } from "components/layout/Page";
import { MeetingForm } from "components/meetings/form/MeetingForm";
import { createOrganizationMeeting } from "core/graphql/mutations";
import { authedUserQuery } from "core/graphql/queries";

const CreateMeetingPage = ({ manager }) => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [createOrgMeeting] = useMutation(createOrganizationMeeting);

  if (!manager.organization) {
    return <Redirect to="/" />;
  }

  const onSubmit = async meetingDto => {
    const variables = { input: { ...meetingDto } };
    try {
      setSubmitting(true);
      await createOrgMeeting({
        variables,
        refetchQueries: [{ query: authedUserQuery }]
      });
      message.success("Meeting successfully saved");
      history.push("/org/meetings");
    } catch (err) {
      console.error(err);
      message.error("There was an error saving the meeting, please try again");
      setSubmitting(false);
    }
  };

  const PAGE_TITLE = `${manager.organization.name}: Add meeting`;
  return (
    <Page title={PAGE_TITLE}>
      <Col
        xxl={{ span: 14, offset: 5 }}
        xl={{ span: 18, offset: 3 }}
        xs={{ span: 24 }}
      >
        <Card title={"Create a new meeting"}>
          <MeetingForm
            onFormSubmit={onSubmit}
            isSubmitting={submitting}
            submitBtnText="Create meeting"
          />
        </Card>
      </Col>
    </Page>
  );
};

CreateMeetingPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { CreateMeetingPage };
