import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col } from "antd";

import { ClientAttendanceStatistics } from "./statistics/ClientAttendanceStatistics";
import { ClientsList } from "./clients/ClientsList";
import { MeetingService } from "core/services/meetings";
import { Page } from "components/layout/Page";

const DashboardPage = ({ manager }) => {
  const [selectedPace, setSelectedPace] = useState("");

  const clientsByFilter = MeetingService.getActiveClientsByPace(
    manager.clients,
    selectedPace
  );

  const PAGE_TITLE = manager.organization
    ? `${manager.organization.name}`
    : "Overview";

  return (
    <Page
      hideBackBtn
      title={PAGE_TITLE}
      subtitle={`Hey ${manager.firstName}! Here is an overview of all your client activity this month`}
    >
      <Col span={24}>
        <ClientAttendanceStatistics
          clients={manager.clients}
          selected={selectedPace}
          setSelected={setSelectedPace}
        />
      </Col>
      <Col span={24}>
        <ClientsList clients={clientsByFilter} />
      </Col>
    </Page>
  );
};

DashboardPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { DashboardPage };
