import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Space } from "antd";

import styles from "./messageBubble.module.scss";

const MessageBubble = ({ message, direction }) => {
  const isIncoming = direction === "in";

  useEffect(() => {
    document.getElementById(message.sid).scrollIntoView({ behavior: "smooth" });
  }, [message.sid]);

  return (
    <div id={message.sid} className={styles.messageContainer}>
      <div className={cn(styles.meta, { [styles.right]: isIncoming })}>
        <Space>
          <ClockCircleOutlined />
          <span className={styles.timestamp}>
            {message.state.timestamp.toLocaleString(undefined, {
              timeStyle: "short",
              dateStyle: "medium"
            })}
          </span>
        </Space>
      </div>
      <div
        className={cn(styles.messageBody, { [styles.incoming]: isIncoming })}
      >
        {message.body}
      </div>
    </div>
  );
};

MessageBubble.propTypes = {
  message: PropTypes.object.isRequired,
  direction: PropTypes.string.isRequired
};

export { MessageBubble };
