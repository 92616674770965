import React from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, Col, Table } from "antd";

import { Page } from "components/layout/Page";
import {
  alphabeticalSort,
  weekDaysMapping,
  parseTime
} from "core/services/tableHelpers";

const { Column } = Table;
const PAGE_TITLE = "Organization Meetings";

const OrganizationMeetingsPage = ({ manager }) => {
  if (!manager.organization) {
    return <Redirect to="/" />;
  }

  const { meetings } = manager.organization;

  return (
    <Page title={PAGE_TITLE}>
      <Col span={24}>
        <Card>
          <Table dataSource={meetings}>
            <Column
              title="Meeting name"
              dataIndex="name"
              key="meetingName"
              sorter={(a, b) => alphabeticalSort(a.name, b.name)}
            />
            <Column
              title="Location"
              key="location"
              render={record => {
                const { city, state, street, postalCode } = record.address;
                return (
                  <span>
                    <address>{`${street} ${city}, ${state} ${postalCode}`}</address>
                  </span>
                );
              }}
            />
            <Column
              title="Has online meetings"
              dataIndex="allowsVirtual"
              key="allowsVirtual"
              render={val => (!!val ? "True" : "False")}
            />
            <Column
              title="Weekday"
              dataIndex="weekDay"
              key="weekDay"
              render={day => weekDaysMapping[day]}
            />
            <Column
              title="Start time"
              dataIndex="startTime"
              key="startTime"
              render={time =>
                parseTime(time).toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit"
                })
              }
            />
            <Column
              fixed="right"
              width={100}
              key="operation"
              render={({ id }) => (
                <Link to={`/org/meetings/${id}/edit`}>
                  <Button type="primary">Edit</Button>
                </Link>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Page>
  );
};

OrganizationMeetingsPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { OrganizationMeetingsPage };
