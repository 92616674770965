import React from "react";
import { Card, Progress, Tag } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as moment from "moment-timezone";

import { MeetingService, PACES } from "core/services/meetings";
import { AttendanceService, ATTENDANCE_STATUS } from "core/services/attendance";

const getProgressStatusForPaceTag = pace => {
  switch (pace) {
    case PACES.REQ_MEETINGS_EXCEEDING:
      return "success";
    case PACES.REQ_MEETINGS_ON_TARGET:
      return "normal";
    default:
      return "exception";
  }
};

const ClientDetailMonthlyProgress = ({ client }) => {
  const pace = MeetingService.getMeetingPace(
    client.meetingsAttended,
    client.meetingsRequiredPerMonth
  );
  const progress =
    (client.meetingsAttended / client.meetingsRequiredPerMonth) * 100;
  const status = getProgressStatusForPaceTag(pace);
  const byStatus = AttendanceService.getStatusCounts(
    client.currentMonthAttendance
  );
  const pending = byStatus[ATTENDANCE_STATUS.PENDING];
  const unverified = byStatus[ATTENDANCE_STATUS.UNVERIFIED];

  const startDate = moment()
    .startOf("month")
    .format("MM/DD/YYYY");
  const endDate = moment().format("MM/DD/YYYY");

  return (
    <Card title="Meeting overview">
      <Progress
        percent={progress}
        status={status}
        format={pct =>
          `${client.meetingsAttended}/${client.meetingsRequiredPerMonth}`
        }
      />
      <div style={{ marginTop: 15 }}>
        {!!unverified && (
          <Tag color="#d45d5d">
            <Link
              style={{ fontSize: ".9rem" }}
              to={`/client/${client.id}/attendance?start=${startDate}&end=${endDate}&status=${ATTENDANCE_STATUS.UNVERIFIED}`}
            >{`${unverified} unverified meeting${
              unverified > 1 ? "s" : ""
            }`}</Link>
          </Tag>
        )}
        {!!pending && (
          <Tag color="#f1c40f">
            <Link
              style={{ fontSize: ".9rem" }}
              to={`/client/${client.id}/attendance?start=${startDate}&end=${endDate}&status=${ATTENDANCE_STATUS.PENDING}`}
            >{`${pending} pending meeting${pending > 1 ? "s" : ""}`}</Link>
          </Tag>
        )}
      </div>
    </Card>
  );
};

ClientDetailMonthlyProgress.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientDetailMonthlyProgress };
