import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input, message } from "antd";

import { auth, ERROR_CODE_MESSAGES } from "core/services/firebase";

const layout = {
  labelCol: { xl: { span: 4 }, lg: { span: 6 } },
  wrapperCol: { xl: { span: 8 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xl: { offset: 4, span: 8 }, lg: { offset: 6, span: 16 } }
};

const ManagerProfilePassword = ({ manager }) => {
  const [passwordForm] = Form.useForm();

  const onSubmitPassword = async values => {
    const { oldPassword, password } = values;
    try {
      const cred = await auth.signInWithEmailAndPassword(
        manager.email,
        oldPassword
      );
      await cred.user.updatePassword(password);
      message.success("Password updated");
      passwordForm.resetFields();
    } catch (e) {
      if (ERROR_CODE_MESSAGES[e.code]) {
        message.error(ERROR_CODE_MESSAGES[e.code]);
      } else {
        console.error(e);
        message.error("Oops, there was a problem");
      }
    }
  };

  return (
    <Card title="Password" style={{ marginBottom: 16 }}>
      <Form
        {...layout}
        name="password_form"
        form={passwordForm}
        onFinish={onSubmitPassword}
      >
        <Form.Item
          name="oldPassword"
          label="Old password"
          rules={[{ required: true, message: "Old password is required" }]}
        >
          <Input type="password" placeholder="Enter your current password" />
        </Form.Item>

        <Form.Item
          name="password"
          label="New password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input type="password" placeholder="Choose a new password" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

ManagerProfilePassword.propTypes = {
  manager: PropTypes.object.isRequired
};

export { ManagerProfilePassword };
