const ellipse = (text, length = 10) => {
  if (text === undefined || text === null) {
    return "";
  }
  if (typeof text !== "string") {
    throw new Error("Typeof argument text must be a string");
  }
  return text.length <= length ? text : `${text.substring(0, length)}...`;
};

const formatPhoneNumber = str => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};
export { ellipse, formatPhoneNumber };
