import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select
} from "antd";
import { MaskedInput } from "antd-mask-input";

import {
  convertToDaysDuration,
  SUPERVISION_DURATIONS
} from "core/services/formHelpers";
import { createClientInvite } from "core/graphql/mutations";
import { Page } from "components/layout/Page";
import { authedUserQuery, clientInvitesQuery } from "core/graphql/queries";

const { Option } = Select;
const defaults = {
  durationUnit: SUPERVISION_DURATIONS.DURATION_MONTHS,
  reqMeetingsFreq: "MONTHLY"
};

const PAGE_TITLE = "Add a new client";

const InviteClientPage = () => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [createInvite] = useMutation(createClientInvite);
  const [inviteForm] = Form.useForm();

  const submit = async values => {
    const {
      email,
      firstName,
      lastName,
      reqMeetings,
      reqMeetingsFreq,
      probationId
    } = values;
    const variables = {
      email,
      firstName,
      lastName,
      reqMeetings,
      reqMeetingsFreq,
      probationId,
      durationDays: convertToDaysDuration(values.duration, values.durationUnit),
      phone: values.phone.replace(/\D/g, "")
    };

    try {
      setSubmitting(true);
      await createInvite({
        variables,
        refetchQueries: [
          { query: clientInvitesQuery },
          { query: authedUserQuery }
        ]
      });
      message.success("Invite sent", 1, () => {
        history.push("/");
      });
    } catch (e) {
      console.error(e.message);
      message.error("Oops, something went wrong");
      setSubmitting(false);
    }
  };

  return (
    <Page title={PAGE_TITLE}>
      <Col
        xxl={{ span: 10, offset: 7 }}
        xl={{ span: 12, offset: 6 }}
        sm={{ span: 18, offset: 3 }}
        xs={{ span: 24 }}
      >
        <Card title="Invite a new client">
          <Form
            layout="vertical"
            name="inviteClient"
            onFinish={submit}
            form={inviteForm}
            initialValues={defaults}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[
                    { required: true, message: "First name is required" }
                  ]}
                >
                  <Input placeholder="John" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  rules={[{ required: true, message: "Last name is required" }]}
                >
                  <Input placeholder="Johnson" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  rules={[
                    { required: true, message: "Email is required" },
                    { type: "email", message: "Must be valid email" }
                  ]}
                  name="email"
                  label="Email"
                >
                  <Input placeholder="example@example.com" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  rules={[
                    { required: true, message: "Phone number is required" }
                  ]}
                >
                  <MaskedInput mask="(111) 111-1111" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="duration"
                  label="Duration of supervision"
                  rules={[
                    {
                      required: true,
                      message: "Supervision duration is required"
                    },
                    { type: "number", message: "Must be valid number" }
                  ]}
                >
                  <InputNumber placeholder="e.g. 6" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="durationUnit"
                  label={<>&nbsp;</>}
                  rules={[
                    {
                      required: true,
                      message: "Duration unit is required"
                    }
                  ]}
                >
                  <Select>
                    <Option value="days">Day(s)</Option>
                    <Option value="months">Month(s)</Option>
                    <Option value="years">Year(s)</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="reqMeetings"
                  label="Required meetings"
                  rules={[
                    {
                      required: true,
                      message: "Required meetings is required"
                    },
                    { type: "number", message: "Must be valid number" }
                  ]}
                >
                  <InputNumber
                    placeholder="e.g. 15"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="reqMeetingsFreq"
                  label={<>&nbsp;</>}
                  rules={[
                    {
                      required: true,
                      message: "Required meeting unit is required"
                    }
                  ]}
                >
                  <Select>
                    <Option value="MONTHLY">Monthly</Option>
                    <Option value="WEEKLY">Weekly</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="probationId" label="Program ID number">
                  <Input placeholder="000-0000000" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item>
                  <Button loading={submitting} type="primary" htmlType="submit">
                    Send invite
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Page>
  );
};

InviteClientPage.propTypes = {};

export { InviteClientPage };
