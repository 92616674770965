import { convertToDaysDuration } from "core/services/formHelpers";

const mapInputToCreateClientDto = input => {
  return {
    password: input.password,
    phone: input.phone,
    firstName: input.firstName,
    lastName: input.lastName,
    email: input.email,
    reqMeetingsFreq: input.reqMeetingsFreq,
    reqMeetings: input.reqMeetings,
    durationDays: convertToDaysDuration(input.durationDays, input.durationUnit),
    probationId: input.probationId,
    address: {
      street: input.streetAddress,
      city: input.city,
      state: input.state,
      postalCode: input.postalCode
    },
    inviteToken: input.token
  };
};

export { mapInputToCreateClientDto };
