import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDERID
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const storage = firebase.storage();

const ERROR_CODE_MESSAGES = {
  "auth/email-already-in-use": "Email address already in use",
  "auth/invalid-email": "Email address is invalid",
  "auth/user-not-found": "Invalid email or password",
  "auth/wrong-password": "Invalid email or password",
  UNKNOWN: "Oops, something went wrong"
};

export { auth, storage, ERROR_CODE_MESSAGES };
