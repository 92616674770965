import React from "react";
import { Row, Col, Card, Statistic } from "antd";
import moment from "moment-timezone";
import PropType from "prop-types";

import { MeetingService, PACES, CLIENT_STATUS } from "core/services/meetings";

import classes from "./clientAttendanceStatistics.module.scss";

const SUFFIX = "client";

const ClientAttendanceStatistics = ({ clients, selected, setSelected }) => {
  const groups = MeetingService.getMeetingPaceCountsForActiveClients(
    clients,
    moment().endOf("month")
  );
  const active = clients.filter(c => c.status === CLIENT_STATUS.ACTIVE);
  return (
    <Row gutter={16}>
      <Col lg={6} md={12} xs={24}>
        <Card
          onClick={() => setSelected("")}
          hoverable={selected !== ""}
          className={selected === "" ? classes.selectedCard : ""}
        >
          <Statistic
            title="All"
            value={active.length}
            suffix={active.length === 1 ? SUFFIX : `${SUFFIX}s`}
          />
        </Card>
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Card
          onClick={() => setSelected(PACES.REQ_MEETINGS_BEHIND)}
          hoverable={selected !== PACES.REQ_MEETINGS_BEHIND}
          className={
            selected === PACES.REQ_MEETINGS_BEHIND ? classes.selectedCard : ""
          }
        >
          <Statistic
            title="Behind requirements"
            value={groups[PACES.REQ_MEETINGS_BEHIND]}
            valueStyle={{ color: "#d45d5d" }}
            suffix={
              groups[PACES.REQ_MEETINGS_BEHIND] === 1 ? SUFFIX : `${SUFFIX}s`
            }
          />
        </Card>
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Card
          onClick={() => setSelected(PACES.REQ_MEETINGS_ON_TARGET)}
          hoverable={selected !== PACES.REQ_MEETINGS_ON_TARGET}
          className={
            selected === PACES.REQ_MEETINGS_ON_TARGET
              ? classes.selectedCard
              : ""
          }
        >
          <Statistic
            title="On target"
            value={groups[PACES.REQ_MEETINGS_ON_TARGET]}
            valueStyle={{ color: "#2378d6" }}
            suffix={
              groups[PACES.REQ_MEETINGS_ON_TARGET] === 1 ? SUFFIX : `${SUFFIX}s`
            }
          />
        </Card>
      </Col>
      <Col lg={6} md={12} xs={24}>
        <Card
          onClick={() => setSelected(PACES.REQ_MEETINGS_EXCEEDING)}
          hoverable={selected !== PACES.REQ_MEETINGS_EXCEEDING}
          className={
            selected === PACES.REQ_MEETINGS_EXCEEDING
              ? classes.selectedCard
              : ""
          }
        >
          <Statistic
            title="Exceeding requirements"
            value={groups[PACES.REQ_MEETINGS_EXCEEDING]}
            valueStyle={{ color: "#2ecc71" }}
            suffix={
              groups[PACES.REQ_MEETINGS_EXCEEDING] === 1 ? SUFFIX : `${SUFFIX}s`
            }
          />
        </Card>
      </Col>
    </Row>
  );
};

ClientAttendanceStatistics.propTypes = {
  clients: PropType.array.isRequired,
  selected: PropType.string.isRequired,
  setSelected: PropType.func.isRequired
};

export { ClientAttendanceStatistics };
