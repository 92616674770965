import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input, message, InputNumber, Select } from "antd";

const { Option } = Select;
const layout = {
  labelCol: { xl: { span: 6 }, lg: { span: 8 } },
  wrapperCol: { xl: { span: 8 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xl: { offset: 6, span: 8 }, lg: { offset: 8, span: 16 } }
};

const EditClientInfoForm = ({ client, updateClient }) => {
  const [submitting, setSubmitting] = useState(false);
  const editInfoInitialValues = {
    durationDays: client.durationDays,
    reqMeetings: client.reqMeetings,
    reqMeetingsFreq: client.reqMeetingsFreq,
    probationId: client.probationId,
    status: client.status
  };

  const onSubmit = async values => {
    const variables = {
      clientId: client.id,
      attendanceReminder: client.attendanceReminder,
      attendanceReminderDays: client.attendanceReminderDays,
      ...values
    };

    try {
      setSubmitting(true);
      await updateClient({ variables });
      message.success("Client program info updated");
      setSubmitting(false);
    } catch (e) {
      console.error(e);
      message.error("Oops, there was a problem");
      setSubmitting(false);
    }
  };

  const frequencySelector = (
    <Form.Item
      noStyle
      name="reqMeetingsFreq"
      rules={[
        {
          required: true,
          message: "Required meeting unit is required"
        }
      ]}
    >
      <Select>
        <Option value="MONTHLY">Per Month</Option>
        <Option value="WEEKLY">Per Week</Option>
      </Select>
    </Form.Item>
  );
  return (
    <Card title="Program Information" style={{ marginBottom: 16 }}>
      <Form
        {...layout}
        name="clientInfo"
        onFinish={onSubmit}
        initialValues={editInfoInitialValues}
      >
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Status is required"
            }
          ]}
        >
          <Select>
            <Option value="ACTIVE">Active</Option>
            <Option value="INACTIVE">Inactive</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="durationDays"
          label="Duration of supervision in days"
          rules={[
            {
              required: true,
              message: "Supervision duration is required"
            },
            { type: "number", message: "Must be valid number" }
          ]}
        >
          <InputNumber placeholder="e.g. 6" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="reqMeetings"
          label="Required meetings"
          rules={[
            {
              required: true,
              message: "Required meetings is required"
            },
            {
              validator: (rule, value) => {
                if (isNaN(value)) {
                  return Promise.reject("Must be valid number");
                } else return Promise.resolve();
              }
            }
          ]}
        >
          <Input
            addonAfter={frequencySelector}
            placeholder="e.g. 15"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item name="probationId" label="Program ID number">
          <Input placeholder="000-0000000" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={submitting} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

EditClientInfoForm.propTypes = {
  client: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired
};

export { EditClientInfoForm };
