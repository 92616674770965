import React from "react";
import PropTypes from "prop-types";
import { Col, PageHeader, Row } from "antd";
import { useHistory } from "react-router-dom";

const Page = ({
  title,
  subtitle,
  breadcrumb,
  hideBackBtn,
  backLink,
  children
}) => {
  const history = useHistory();
  const goBack = () => {
    if (backLink) {
      history.push(backLink);
      return;
    }
    history.goBack();
  };
  return (
    <div>
      <PageHeader
        onBack={hideBackBtn ? null : goBack}
        title={title}
        subTitle={subtitle}
      ></PageHeader>
      {breadcrumb && (
        <Row>
          <Col span={24}>{breadcrumb}</Col>
        </Row>
      )}
      <Row style={{ padding: "0 24px", marginTop: 36 }} gutter={16}>
        {children}
      </Row>
    </div>
  );
};

Page.propTypes = {
  /** Title shown at top of page */
  title: PropTypes.string.isRequired,
  /** Additional text shown next to title */
  subtitle: PropTypes.string,
  /** Hides back button at top of page */
  hideBackBtn: PropTypes.bool,
  /** Optional back button link */
  backLink: PropTypes.string,
  /** Child nodes */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  /** Optional breadcrumb to show under title and above content */
  breadcrumb: PropTypes.element
};

export { Page };
