import React from "react";
import { useQuery } from "@apollo/client";
import { Card, Typography, Space, Empty } from "antd";
import PropTypes from "prop-types";
import * as moment from "moment-timezone";

import { Spinner } from "components/util/Spinner";
import { managerNotesQuery } from "core/graphql/queries";
import { ClientDetailNotesForm } from "./ClientDetailNotesForm";

import classes from "./clientDetailNotes.module.scss";

const { Text } = Typography;

const ClientDetailNotes = ({ clientId }) => {
  const queryOptions = {
    variables: {
      clientId
    }
  };

  const { loading, data } = useQuery(managerNotesQuery, queryOptions);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Card title="Notes">
      <Space direction="vertical" style={{ width: "100%" }}>
        <ClientDetailNotesForm clientId={clientId} />
        <div className={classes.notesContainer}>
          {data.managerNotes.map(note => {
            return (
              <div key={note.note}>
                <Text style={{ fontSize: "0.8rem", color: "#bdbdbd" }}>
                  {moment(note.createdAt).format("MMMM Do, YYYY")}
                </Text>
                <p>{note.note}</p>
              </div>
            );
          })}
          {!data.managerNotes.length && (
            <Empty
              style={{ padding: "3rem 0" }}
              description="No notes found for this client"
            />
          )}
        </div>
      </Space>
    </Card>
  );
};

ClientDetailNotes.propTypes = {
  clientId: PropTypes.number.isRequired
};

export { ClientDetailNotes };
