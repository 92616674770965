import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card } from "antd";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({ errorInfo });
  }
  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return (
        <Card
          style={{ width: "50%", margin: "200px auto 0 auto" }}
          title="There was an error in loading this page."
          extra={
            <span
              style={{ cursor: "pointer", color: "#0077FF" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload this page
            </span>
          }
        >
          <details className="error-details">
            <summary>Click for error details</summary>
            {errorInfo && errorInfo.componentStack.toString()}
          </details>
        </Card>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};
