import React from "react";
import { Card, Divider } from "antd";

import { Login } from "./Login";
import Logo from "assets/tbv-logo-full-color-no-tagline.svg";

const LoginPage = () => {
  return (
    <Card style={{ borderRadius: ".5rem" }}>
      <div style={{ textAlign: "center", marginBottom: "2rem" }}>
        <img src={Logo} alt="logo" style={{ width: "10rem" }} />
      </div>
      <Divider />
      <Login />
    </Card>
  );
};

export { LoginPage };
