import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UserAddOutlined } from "@ant-design/icons";
import { Button, Card, Col, Space, Table } from "antd";
import * as moment from "moment-timezone";

import { clientInvitesQuery } from "core/graphql/queries";
import { alphabeticalSort } from "core/services/tableHelpers";
import { Page } from "components/layout/Page";
import { ResendInviteAction } from "./invite/ResendInviteAction";
import { CancelInviteAction } from "./invite/CancelInviteAction";

const { Column } = Table;
const PAGE_TITLE = "Pending Invites";

const PendingInvitesPage = () => {
  const { loading, data } = useQuery(clientInvitesQuery);

  const clientInvites = data && data.meManager && data.meManager.clientInvites;
  const subtitle =
    clientInvites && clientInvites.length
      ? clientInvites.length > 1
        ? `You have sent ${clientInvites.length} invites that have not been accepted yet`
        : "You have sent 1 invite that has not been accepted yet"
      : null;

  return (
    <Page title={PAGE_TITLE} subtitle={subtitle}>
      <Col span={24}>
        <Card
          extra={
            <Link to="/client/invite">
              <Button type="ghost">
                <UserAddOutlined />
                Add client
              </Button>
            </Link>
          }
        >
          <Table
            dataSource={clientInvites}
            loading={loading}
            scroll={{ x: true }}
          >
            <Column
              title="First name"
              dataIndex="firstName"
              key="firstName"
              sorter={(a, b) => alphabeticalSort(a.firstName, b.firstName)}
            />
            <Column
              title="Last name"
              dataIndex="lastName"
              key="lastName"
              sorter={(a, b) => alphabeticalSort(a.lastName, b.lastName)}
            />
            <Column title="Phone number" dataIndex="phone" key="phone" />
            <Column title="Email address" dataIndex="email" key="email" />
            <Column
              title="Invited on"
              dataIndex="createdAt"
              key="invitedOn"
              render={dtString => (
                <span>{moment(dtString).format("MMM Do YYYY")}</span>
              )}
            />
            <Column
              title="Last sent on"
              dataIndex="updatedAt"
              key="lastSentOn"
              render={dtString => (
                <span>{moment(dtString).format("MMM Do YYYY")}</span>
              )}
            />
            <Column
              fixed="right"
              width={100}
              key="operation"
              render={({ token }) => (
                <div style={{ display: "flex" }}>
                  <Space>
                    <ResendInviteAction token={token} />
                    <CancelInviteAction token={token} />
                  </Space>
                </div>
              )}
            />
          </Table>
        </Card>
      </Col>
    </Page>
  );
};

PendingInvitesPage.propTypes = {};

export { PendingInvitesPage };
