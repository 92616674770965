import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input, message } from "antd";

import { auth, ERROR_CODE_MESSAGES } from "core/services/firebase";

const layout = {
  labelCol: { xl: { span: 4 }, lg: { span: 6 } },
  wrapperCol: { xl: { span: 8 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xl: { offset: 4, span: 8 }, lg: { offset: 6, span: 16 } }
};

const ManagerProfileEmail = ({ manager, updateManager }) => {
  const [emailForm] = Form.useForm();
  const emailInitialValues = {
    email: manager.email
  };

  const onSubmitEmail = async values => {
    const { email, password } = values;
    const variables = {
      email,
      firstName: manager.firstName,
      lastName: manager.lastName,
      phone: manager.phone
    };
    try {
      const cred = await auth.signInWithEmailAndPassword(
        manager.email,
        password
      );
      await cred.user.updateEmail(email);
      await updateManager({ variables });
      message.success("Email address updated");
      emailForm.resetFields(["password"]);
    } catch (e) {
      if (ERROR_CODE_MESSAGES[e.code]) {
        message.error(ERROR_CODE_MESSAGES[e.code]);
      } else {
        console.error(e);
        message.error("Oops, there was a problem");
      }
    }
  };

  return (
    <Card title="Email" style={{ marginBottom: 16 }}>
      <Form
        {...layout}
        name="email_form"
        initialValues={emailInitialValues}
        form={emailForm}
        onFinish={onSubmitEmail}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Password is required to update email"
            }
          ]}
        >
          <Input
            type="password"
            placeholder="Password is required to update email"
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

ManagerProfileEmail.propTypes = {
  manager: PropTypes.object.isRequired,
  updateManager: PropTypes.func.isRequired
};

export { ManagerProfileEmail };
