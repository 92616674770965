import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Button, Card, Col } from "antd";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";

import { Spinner } from "components/util/Spinner";
import { Page } from "components/layout/Page";
import { clientsActivityQuery } from "core/graphql/queries";
import { ClientReportDocument } from "./ClientReportDocument";

const ClientReport = ({ client, manager, reportConfig, closeReport }) => {
  const { loading, data } = useQuery(clientsActivityQuery, reportConfig);
  const fileName = `${client.firstName}${client.lastName}_${reportConfig.variables.startDate}-${reportConfig.variables.endDate}.pdf`;

  if (loading) {
    return <Spinner />;
  }

  const close = (
    <CloseOutlined onClick={closeReport} style={{ cursor: "pointer" }} />
  );

  return (
    <Page hideBackBtn title="">
      <Col xxl={{ span: 18, offset: 3 }} xs={{ span: 24 }}>
        <Card
          title={close}
          style={{ marginTop: -36 }}
          bodyStyle={{ width: "100%" }}
          extra={
            <PDFDownloadLink
              document={
                <ClientReportDocument
                  client={client}
                  manager={manager}
                  attendance={data.clientsAttendanceByDate}
                />
              }
              fileName={fileName}
            >
              <Button type="primary">
                <DownloadOutlined /> Download report
              </Button>
            </PDFDownloadLink>
          }
        >
          <div style={{ display: "flex", height: "80vh" }}>
            <PDFViewer style={{ width: "100%", height: "100%" }}>
              <ClientReportDocument
                client={client}
                manager={manager}
                attendance={data.clientsAttendanceByDate}
              />
            </PDFViewer>
          </div>
        </Card>
      </Col>
    </Page>
  );
};

ClientReport.propTypes = {
  client: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  reportConfig: PropTypes.object.isRequired,
  closeReport: PropTypes.func.isRequired
};

export { ClientReport };
