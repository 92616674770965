import { gql } from "@apollo/client";

import {
  UserDetailFragment,
  ManagerDetailFragment,
  AdminDetailFragment,
  ClientMonthlyCountFragment,
  ManagerNoteFragment,
  AttendanceFragment
} from "./fragments";

const authedUserQuery = gql`
  query AuthedUser {
    authedUser {
      client {
        ...UserDetail
      }
      manager {
        ...ManagerDetail
      }
      admin {
        ...AdminDetail
      }
    }
  }
  ${UserDetailFragment}
  ${ManagerDetailFragment}
  ${AdminDetailFragment}
`;

const getClientQuery = gql`
  query Client($id: String!) {
    user(input: { id: $id }) {
      organization {
        id
        name
      }
      ...UserDetail
    }
  }
  ${UserDetailFragment}
`;

const clientMonthlyCountQuery = gql`
  query ClientMonthlyCount(
    $clientId: String!
    $startDate: ISODateTime!
    $endDate: ISODateTime!
  ) {
    clientMonthlyAttendanceCount(
      input: { clientId: $clientId, startDate: $startDate, endDate: $endDate }
    ) {
      ...MonthlyCountDetail
    }
  }
  ${ClientMonthlyCountFragment}
`;

const managerNotesQuery = gql`
  query ManagerNotes($clientId: String!) {
    managerNotes(input: { clientId: $clientId, limit: 10, offset: 0 }) {
      ...ManagerNoteDetail
    }
  }
  ${ManagerNoteFragment}
`;

const clientsActivityQuery = gql`
  query ClientsActivity(
    $clientId: String!
    $startDate: ISODateTime!
    $endDate: ISODateTime!
    $validationStatuses: [AttendanceValidationStatus]
  ) {
    clientsAttendanceByDate(
      input: {
        clientId: $clientId
        startDate: $startDate
        endDate: $endDate
        validationStatuses: $validationStatuses
      }
    ) {
      ...AttendanceDetail
    }
  }
  ${AttendanceFragment}
`;

const getMeetingQuery = gql`
  query meeting($input: MeetingIdInput) {
    meeting(input: $input) {
      id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay
      location {
        lat
        lng
      }
      createdBy {
        id
        client {
          id
          firstName
          lastName
          photoUrl
          phone
          email
        }
      }
      organizationId
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
      isDemoMeeting
      temporarilySuspended
      meetingPassword
    }
  }
`;

const clientInvitesQuery = gql`
  query MeManager($type: String) {
    meManager(input: { type: $type }) {
      clientInvites {
        id
        firstName
        lastName
        phone
        email
        token
        createdAt
        updatedAt
      }
    }
  }
`;

const getClientInviteQuery = gql`
  query getInvite($token: String!) {
    getInvite(input: { token: $token }) {
      id
      token
      firstName
      lastName
      phone
      email
      probationId
      reqMeetings
      reqMeetingsFreq
      durationDays
      createdAt
    }
  }
`;

const getManagerInviteQuery = gql`
  query getManagerInvite($token: String!) {
    getManagerInvite(input: { token: $token }) {
      id
      token
      firstName
      lastName
      phone
      email
      roleName
      organizationId
      createdAt
    }
  }
`;

const getManagerChatTokenQuery = gql`
  query getManagerChatToken {
    getManagerChatToken {
      token
    }
  }
`;

const managerChatsQuery = gql`
  query getManagerChats {
    getManagerChats {
      id
      clientId
      managerId
      conversationSid
      clientParticipantSid
      managerIdentity
      token
    }
  }
`;

const managerChatQuery = gql`
  query getManagerChat($input: ManagerChatWhereInput!) {
    getManagerChat(input: $input) {
      id
      clientId
      managerId
      conversationSid
      clientParticipantSid
      managerIdentity
      token
    }
  }
`;

export {
  authedUserQuery,
  getClientQuery,
  clientMonthlyCountQuery,
  clientsActivityQuery,
  managerNotesQuery,
  clientInvitesQuery,
  getClientInviteQuery,
  getManagerInviteQuery,
  getMeetingQuery,
  getManagerChatTokenQuery,
  managerChatsQuery,
  managerChatQuery
};
