import React, { useState, useContext } from "react";
import { Form, Input, Button, Anchor, Typography } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import { auth, ERROR_CODE_MESSAGES } from "core/services/firebase";
import { AuthContext } from "core/providers/AuthProvider";

const Login = () => {
  const { setLoading: setUserLoading } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onFinish = async values => {
    try {
      // Set spinner on button and log in to firebase
      setLoading(true);
      await auth.signInWithEmailAndPassword(values.email, values.password);
      // Reset state to wait for onAuthStateChanged to run again and redirect
      setUserLoading(true);
      return history.push("/");
    } catch (e) {
      setErrorMessage(
        ERROR_CODE_MESSAGES[e.code] || ERROR_CODE_MESSAGES.UNKNOWN
      );
      setLoading(false);
    }
  };

  return (
    <Form name="login" layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: "Email is required" },
          { type: "email", message: "Must be valid email" }
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          size="large"
          placeholder="Email Address"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Password is required" },
          { min: 6, message: "Password must be at least 6 characters" }
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          validateTrigger="onBlur"
          size="large"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Anchor style={{ float: "right" }}>
          <Link to="/forgotpass">Forgot password?</Link>
        </Anchor>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          block
        >
          Log in
        </Button>
      </Form.Item>
      {errorMessage && (
        <Form.Item>
          <Typography.Text type="danger">{errorMessage}</Typography.Text>
        </Form.Item>
      )}
    </Form>
  );
};

export { Login };
