import React from "react";
import { Link } from "react-router-dom";
import { UserAddOutlined } from "@ant-design/icons";
import { Row, Col, Card, Empty, Button } from "antd";
import PropTypes from "prop-types";

import { ClientsListRow } from "./ClientsListRow";

import classes from "./clientsList.module.scss";

const ClientsList = ({ clients }) => {
  return (
    <div className={classes.clientsListContainer}>
      <Row>
        <Col span={24}>
          <Card
            title="Active clients activity"
            bodyStyle={{ padding: "1rem" }}
            extra={
              <Link to="/client/invite">
                <Button type="ghost">
                  <UserAddOutlined />
                  Add client
                </Button>
              </Link>
            }
          >
            {!clients.length && (
              <Empty
                style={{ padding: "12rem 0" }}
                description="No clients found for this filter"
              />
            )}
            {clients.map((c, i) => (
              <ClientsListRow key={i} client={c} />
            ))}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ClientsList.propTypes = {
  clients: PropTypes.array.isRequired
};

export { ClientsList };
