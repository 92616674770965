import { gql } from "@apollo/client";

import {
  AttendanceFragment,
  ManagerDetailFragment,
  UserDetailFragment
} from "./fragments";

const createClient = gql`
  mutation CreateUser(
    $authProviderId: String!
    $email: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $reqMeetings: Int
    $reqMeetingsFreq: MeetingFrequency
    $durationDays: Int
    $probationId: String
    $address: AddressInput
    $inviteToken: String
  ) {
    createUser(
      input: {
        authProviderId: $authProviderId
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        reqMeetings: $reqMeetings
        reqMeetingsFreq: $reqMeetingsFreq
        probationId: $probationId
        durationDays: $durationDays
        address: $address
        inviteToken: $inviteToken
      }
    ) {
      id
      email
      firstName
      lastName
      email
      role
    }
  }
`;

const createManagerNote = gql`
  mutation CreateManagerNote($clientId: String!, $note: String!) {
    createManagerNote(input: { clientId: $clientId, note: $note }) {
      note
      createdAt
    }
  }
`;

const updateClientAttendance = gql`
  mutation UpdateClientAttendance(
    $clientId: String!
    $attendanceId: String!
    $validationStatus: AttendanceValidationStatus!
  ) {
    updateClientAttendance(
      input: {
        clientId: $clientId
        attendanceId: $attendanceId
        validationStatus: $validationStatus
      }
    ) {
      ...AttendanceDetail
    }
  }
  ${AttendanceFragment}
`;

const updateManager = gql`
  mutation UpdateClient(
    $email: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $photoUrl: String
  ) {
    updateManager(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        photoUrl: $photoUrl
      }
    ) {
      ...ManagerDetail
    }
  }
  ${ManagerDetailFragment}
`;

const updateClient = gql`
  mutation UpdateClient(
    $clientId: String!
    $reqMeetings: Int!
    $reqMeetingsFreq: MeetingFrequency!
    $durationDays: Int!
    $probationId: String
    $attendanceReminder: Boolean!
    $attendanceReminderDays: Int!
    $status: CLIENT_STATUS
  ) {
    updateClient(
      input: {
        clientId: $clientId
        reqMeetings: $reqMeetings
        reqMeetingsFreq: $reqMeetingsFreq
        probationId: $probationId
        durationDays: $durationDays
        attendanceReminder: $attendanceReminder
        attendanceReminderDays: $attendanceReminderDays
        status: $status
      }
    ) {
      ...UserDetail
    }
  }
  ${UserDetailFragment}
`;

const createClientInvite = gql`
  mutation CreateClientInvite(
    $email: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $reqMeetings: Int!
    $reqMeetingsFreq: MeetingFrequency!
    $durationDays: Int!
    $probationId: String
  ) {
    createInvite(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        reqMeetings: $reqMeetings
        reqMeetingsFreq: $reqMeetingsFreq
        probationId: $probationId
        durationDays: $durationDays
      }
    ) {
      id
      createdAt
    }
  }
`;

const resendClientInvite = gql`
  mutation resendInvite($input: InviteResendInput) {
    resendInvite(input: $input) {
      id
      firstName
      lastName
      phone
      email
      token
      createdAt
      updatedAt
    }
  }
`;

const cancelClientInvite = gql`
  mutation cancelInvite($input: InviteCancelInput) {
    cancelInvite(input: $input)
  }
`;

const createOrganizationMeeting = gql`
  mutation createOrgMeeting($input: OrgMeetingCreateInput!) {
    createOrgMeeting(input: $input) {
      id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay
      location {
        lat
        lng
      }
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
      meetingPassword
    }
  }
`;

const editMeeting = gql`
  mutation updateMeeting($input: MeetingUpdateInput!) {
    updateMeeting(input: $input) {
      id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay
      location {
        lat
        lng
      }
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
    }
  }
`;

const createClientConversation = gql`
  mutation create($input: ManagerChatCreateInput!) {
    createManagerChat(input: $input) {
      id
      clientId
      managerId
      conversationSid
      clientParticipantSid
      managerIdentity
      token
    }
  }
`;

export {
  createClient,
  createManagerNote,
  updateClientAttendance,
  updateManager,
  updateClient,
  createClientInvite,
  resendClientInvite,
  cancelClientInvite,
  createOrganizationMeeting,
  editMeeting,
  createClientConversation
};
