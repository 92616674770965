import React, { useState } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import { useMutation } from "@apollo/client";

import { clientInvitesQuery } from "core/graphql/queries";
import { cancelClientInvite } from "core/graphql/mutations";

const CancelInviteAction = ({ token }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [cancelInvite] = useMutation(cancelClientInvite);

  const handleOk = async () => {
    const variables = {
      input: { token }
    };
    try {
      setConfirmLoading(true);
      await cancelInvite({
        variables,
        refetchQueries: [{ query: clientInvitesQuery }]
      });
      setVisible(false);
      setConfirmLoading(false);
      message.success("Invite canceled");
    } catch (e) {
      console.errror(e.message);
      message.error("Oops, something went wrong please try again");
    }
  };
  return (
    <Popconfirm
      title="Are you sure you want to cancel this invite?"
      onConfirm={handleOk}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: confirmLoading }}
      visible={visible}
    >
      <Button type="danger" onClick={() => setVisible(true)}>
        <DeleteOutlined />
        Cancel invite
      </Button>
    </Popconfirm>
  );
};

CancelInviteAction.propTypes = {
  token: PropTypes.string.isRequired
};

export { CancelInviteAction };
