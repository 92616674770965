import * as moment from "moment-timezone";

const mapInputToMeetingDto = input => {
  const [start, end] = input.meetingTime;
  const startTime = start.format("HH:mm");
  const endTime = end.format("HH:mm");
  return {
    name: input.name,
    weekDay: input.weekDay,
    address: {
      street: input.street,
      city: input.city,
      state: input.state,
      postalCode: input.postalCode
    },
    location: {
      lat: input.lat,
      lng: input.lng
    },
    startTime,
    endTime,
    type: input.type,
    allowsVirtual: input.allowsVirtual,
    virtualUrl: input.virtualUrl,
    meetingPassword: input.meetingPassword
  };
};

const mapMeetingToInitialValues = meeting => {
  const meetingTime = [];
  let meetingLocation = null;

  if (meeting.address) {
    const {
      address: { street, city, state, postalCode }
    } = meeting;
    const formatAddress = `${street} ${city}, ${state} ${postalCode}`;
    meetingLocation = formatAddress;
  }

  if (meeting.startTime) {
    meetingTime[0] = moment("2020-12-01 " + meeting.startTime);
  }

  if (meeting.endTime) {
    meetingTime[1] = moment("2020-12-01 " + meeting.endTime);
  }
  return {
    ...meeting,
    meetingTime,
    meetingLocation
  };
};

export { mapInputToMeetingDto, mapMeetingToInitialValues };
