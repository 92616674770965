import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UserOutlined } from "@ant-design/icons";

import { ClientDetailSidebar } from "./detail/ClientDetailSideBar";
import { AttendanceTable } from "./attendance/AttendanceTable";
import { Spinner } from "components/util/Spinner";
import { Page } from "components/layout/Page";
import { getClientQuery } from "core/graphql/queries";

const PAGE_TITLE = "Manage Attendance";

const AttendancePage = ({ manager }) => {
  const { id } = useParams();

  const queryOptions = {
    variables: {
      id
    }
  };

  const { loading, data } = useQuery(getClientQuery, queryOptions);

  if (loading) {
    return <Spinner />;
  }

  const client = data.user;
  const breadcrumb = (
    <Breadcrumb style={{ padding: "0 24px" }}>
      <Breadcrumb.Item>
        <UserOutlined />
        <span>Clients</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>{`${client.firstName} ${client.lastName}`}</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>Attendance</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Page title={PAGE_TITLE} breadcrumb={breadcrumb}>
      <ClientDetailSidebar client={client} manager={manager} />
      <AttendanceTable client={client} />
    </Page>
  );
};

AttendancePage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { AttendancePage };
