import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Col, Card, message } from "antd";

import { Page } from "components/layout/Page";
import { MeetingForm } from "components/meetings/form/MeetingForm";
import { editMeeting } from "core/graphql/mutations";
import { authedUserQuery, getMeetingQuery } from "core/graphql/queries";
import { Spinner } from "components/util/Spinner";

const EditMeetingPage = ({ manager }) => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [editMeetingMutation] = useMutation(editMeeting);
  const { id } = useParams();

  const queryOptions = {
    variables: {
      input: { id }
    },
    fetchPolicy: "network-only"
  };

  const { loading, data } = useQuery(getMeetingQuery, queryOptions);

  if (loading) {
    return <Spinner />;
  }

  const { meeting } = data;
  if (
    !manager.organization ||
    Number(manager.organization.id) !== meeting.organizationId
  ) {
    return <Redirect to="/" />;
  }

  const onSubmit = async meetingDto => {
    const variables = { input: { ...meetingDto, id } };
    try {
      setSubmitting(true);
      await editMeetingMutation({
        variables,
        refetchQueries: [{ query: authedUserQuery }]
      });
      message.success("Meeting successfully updated");
      history.push("/org/meetings");
    } catch (err) {
      console.error(err);
      message.error(
        "There was an error updating the meeting, please try again"
      );
      setSubmitting(false);
    }
  };

  const PAGE_TITLE = `${manager.organization.name}: Edit meeting`;
  return (
    <Page title={PAGE_TITLE}>
      <Col
        xxl={{ span: 14, offset: 5 }}
        xl={{ span: 18, offset: 3 }}
        xs={{ span: 24 }}
      >
        <Card title={`Edit ${meeting.name}`}>
          <MeetingForm
            onFormSubmit={onSubmit}
            isSubmitting={submitting}
            initialValues={meeting}
            submitBtnText="Update meeting"
          />
        </Card>
      </Col>
    </Page>
  );
};

EditMeetingPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { EditMeetingPage };
