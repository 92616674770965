import React from "react";
import PropTypes from "prop-types";

import Logo from "assets/tbv-logo-white.svg";
import AppIcon from "assets/tbv-app-icon.svg";

const AppLogo = ({ isCollapsed }) => {
  if (isCollapsed) {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src={AppIcon}
          alt="logo"
          style={{ margin: "1rem 0 1rem 0", width: "2.5rem" }}
        />
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center", margin: "1rem 0 1rem -30px" }}>
      <img src={Logo} alt="logo" style={{ width: "6rem" }} />
    </div>
  );
};

AppLogo.propTypes = {
  isCollapsed: PropTypes.bool
};

export { AppLogo };
