import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Col } from "antd";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { UserOutlined } from "@ant-design/icons";

import { ClientDetailSidebar } from "./detail/ClientDetailSideBar";
import { Spinner } from "components/util/Spinner";
import { Page } from "components/layout/Page";
import { getClientQuery } from "core/graphql/queries";
import { EditClientInfoForm } from "./info/EditClientInfoForm";
import { updateClient } from "core/graphql/mutations";
import { EditClientMeetingReminderForm } from "./info/EditClientMeetingReminderForm";

const PAGE_TITLE = "Edit information";

const EditClientInfoPage = ({ manager }) => {
  const { id } = useParams();

  const queryOptions = {
    variables: {
      id
    }
  };

  const { loading, data } = useQuery(getClientQuery, queryOptions);
  const [updateClientMut] = useMutation(updateClient);

  if (loading) {
    return <Spinner />;
  }

  const client = data.user;
  const breadcrumb = (
    <Breadcrumb style={{ padding: "0 24px" }}>
      <Breadcrumb.Item>
        <UserOutlined />
        <span>Clients</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>{`${client.firstName} ${client.lastName}`}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Page title={PAGE_TITLE} breadcrumb={breadcrumb}>
      <ClientDetailSidebar client={client} manager={manager} />
      <Col xl={18} lg={16}>
        <EditClientInfoForm client={client} updateClient={updateClientMut} />
        <EditClientMeetingReminderForm
          client={client}
          updateClient={updateClientMut}
        />
      </Col>
    </Page>
  );
};

EditClientInfoPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { EditClientInfoPage };
