import React, { useState } from "react";
import PropTypes from "prop-types";
import { SendOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import { useMutation } from "@apollo/client";

import { resendClientInvite } from "core/graphql/mutations";
import { clientInvitesQuery } from "core/graphql/queries";

const ResendInviteAction = ({ token }) => {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [resendInvite] = useMutation(resendClientInvite);

  const handleOk = async () => {
    const variables = {
      input: { token }
    };
    try {
      setConfirmLoading(true);
      await resendInvite({
        variables,
        refetchQueries: [{ query: clientInvitesQuery }]
      });
      setVisible(false);
      setConfirmLoading(false);
      message.success("Invite resent");
    } catch (e) {
      console.errror(e.message);
      message.error("Oops, something went wrong please try again");
    }
  };
  return (
    <Popconfirm
      title="Are you sure you want to resend?"
      onConfirm={handleOk}
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: confirmLoading }}
      visible={visible}
    >
      <Button type="primary" onClick={() => setVisible(true)}>
        <SendOutlined />
        Resend
      </Button>
    </Popconfirm>
  );
};

ResendInviteAction.propTypes = {
  token: PropTypes.string.isRequired
};

export { ResendInviteAction };
