import { ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "apollo-cache-inmemory";
import * as moment from "moment-timezone";

import { auth } from "core/services/firebase";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

const authLink = setContext(async (_, { headers }) => {
  let token;
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken();
  }
  return {
    headers: {
      ...headers,
      authorization: token || "",
      "X-TBV-User-Timezone-Id": moment.tz.guess()
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export { client };
