import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { auth } from "core/services/firebase";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsub = auth.onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(() => {
          setCurrentUser(user);
          setLoading(false);
        });
      } else {
        setLoading(false);
        setCurrentUser(user);
      }
    });
    return () => unsub();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        setLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export { AuthProvider, AuthContext };
