import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "antd";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { UserOutlined } from "@ant-design/icons";

import { ClientDetailSidebar } from "./detail/ClientDetailSideBar";
import { ClientDetailContent } from "./detail/ClientDetailContent";
import { Page } from "components/layout/Page";
import { getClientQuery } from "core/graphql/queries";
import { Spinner } from "components/util/Spinner";

const ClientDetailPage = ({ manager }) => {
  const { id } = useParams();

  const queryOptions = {
    variables: {
      id
    }
  };

  const { loading, data } = useQuery(getClientQuery, queryOptions);

  if (loading) {
    return <Spinner />;
  }

  if (!data.user) {
    return <Redirect to="/" />;
  }

  const client = data.user;
  const PAGE_TITLE = "Client Profile";

  const breadcrumb = (
    <Breadcrumb style={{ padding: "0 24px" }}>
      <Breadcrumb.Item>
        <UserOutlined />
        <span>Clients</span>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <span>{`${client.firstName} ${client.lastName}`}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  return (
    <Page title={PAGE_TITLE} breadcrumb={breadcrumb}>
      <ClientDetailSidebar client={client} manager={manager} withActions />
      <ClientDetailContent client={client} manager={manager} />
    </Page>
  );
};

ClientDetailPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { ClientDetailPage };
