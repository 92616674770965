/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import * as moment from "moment-timezone";
import { Tag } from "antd";

import { ATTENDANCE_STATUS_TAG_COLOR } from "core/services/attendance";
import { ellipse } from "core/services/format";
import { AttendanceTableVerificationAction } from "./AttendanceTableVerificationAction";

export const getColumns = (clientId, currParams) => [
  {
    title: "Meeting",
    dataIndex: "meeting",
    key: "meeting",
    render: meeting => <span>{meeting.name}</span>
  },
  {
    title: "Meeting Time",
    dataIndex: "meetingStartTime",
    key: "meetingStartTime",
    sorter: (a, b) =>
      moment(a.meetingStartTime).unix() - moment(b.meetingStartTime).unix(),
    render: dtString => (
      <span>{moment(dtString).format("MMM Do YYYY, h:mm a")}</span>
    )
  },
  {
    title: "Duration",
    dataIndex: "meetingDurationMins",
    key: "meetingDurationMins",
    render: minutes => {
      const dur = moment.duration(minutes, "m");
      const hours = dur.hours() > 0 ? `${dur.hours()}h` : "";
      const mins = dur.minutes() > 0 ? `${dur.minutes()}m` : "";
      return <span>{`${hours} ${mins}`}</span>;
    }
  },
  {
    title: "Location",
    dataIndex: "meeting",
    key: "meetingLocation",
    render: (meeting, att) => (
      <span>
        {att.isVirtual
          ? "Online"
          : ellipse(`${meeting.address.city}, ${meeting.address.state}`, 20)}
      </span>
    )
  },
  {
    title: "Status",
    dataIndex: "validation",
    key: "validation",
    sorter: (a, b) => a.validation.status.length - b.validation.status.length,
    render: validation => (
      <Tag color={ATTENDANCE_STATUS_TAG_COLOR[validation.status]}>
        {validation.status}
      </Tag>
    )
  },
  {
    title: "Check In",
    dataIndex: "checkIn",
    key: "checkIn",
    render: dtString => <span>{moment(dtString).format("h:mm a")}</span>
  },
  {
    title: "Check Out",
    dataIndex: "checkOut",
    key: "checkOut",
    render: dtString =>
      dtString ? (
        <span>{moment(dtString).format("h:mm a")}</span>
      ) : (
        <span>Not recorded</span>
      )
  },
  {
    key: "operation",
    fixed: "right",
    width: 100,
    render: record => (
      <AttendanceTableVerificationAction
        record={record}
        clientId={parseInt(clientId, 10)}
        currQueryParams={currParams}
      />
    )
  }
];
