import React from "react";
import { Col } from "antd";
import { useMutation, useQuery } from "@apollo/client";

import { authedUserQuery } from "core/graphql/queries";
import { updateManager } from "core/graphql/mutations";
import { ManagerProfileSidebar } from "./ManagerProfileSidebar";
import { ManagerProfileContactInfo } from "./ManagerProfileContactInfo";
import { ManagerProfileEmail } from "./ManagerProfileEmail";
import { ManagerProfilePassword } from "./ManagerProfilePassword";
import { Page } from "components/layout/Page";
import { Spinner } from "components/util/Spinner";

const ManagerProfilePage = () => {
  const { loading, data } = useQuery(authedUserQuery);
  const [updateManagerMut] = useMutation(updateManager);

  if (loading) {
    return <Spinner />;
  }

  const {
    authedUser: { manager }
  } = data;

  const PAGE_TITLE = "Your Profile";

  return (
    <Page title={PAGE_TITLE}>
      <Col xl={6} lg={8}>
        <ManagerProfileSidebar manager={manager} />
      </Col>
      <Col xl={18} lg={16}>
        <ManagerProfileContactInfo
          manager={manager}
          updateManager={updateManagerMut}
        />
        <ManagerProfileEmail
          manager={manager}
          updateManager={updateManagerMut}
        />
        <ManagerProfilePassword manager={manager} />
      </Col>
    </Page>
  );
};

export { ManagerProfilePage };
