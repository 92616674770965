import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useQuery } from "@apollo/client";
import { Client as ConversationsClient } from "@twilio/conversations";
import { MessageTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Layout, Result } from "antd";
import { useQueryParam } from "use-query-params";

import {
  getManagerChatTokenQuery,
  managerChatsQuery
} from "core/graphql/queries";
import { CLIENT_EVENTS } from "core/constants/messaging";
import { SelectMessageModal } from "./SelectMessageModal";
import { MessagesList } from "./MessagesList";
import { Spinner } from "components/util/Spinner";
import { Conversation } from "./Conversation";
import { Centered } from "components/util/Centered";

import styles from "./messagingCenter.module.scss";
import { useGetOrSetConversation } from "./hooks/useGetOrSetConversation";

const { Content, Sider } = Layout;

const MessagingCenter = ({ manager }) => {
  const [clientId, setClientId] = useQueryParam("clientId");
  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedConversationSid, setSelectedConversationSid] = useState(null);
  const [conversations, _setConversations] = useState([]);
  const conversationsRef = useRef(conversations);
  const setConversations = data => {
    conversationsRef.current = data;
    _setConversations(data);
  };

  const client = useApolloClient();
  const { data, loading: loadingLocalChatObjs } = useQuery(managerChatsQuery);

  useGetOrSetConversation(
    setSelectedConversationSid,
    clientId,
    manager.clients,
    data?.getManagerChats
  );

  useEffect(() => {
    async function initTwilioClient() {
      window.conversationsClient = ConversationsClient;
      const result = await client.query({ query: getManagerChatTokenQuery });
      const token = result.data.getManagerChatToken.token;
      const twClient = await ConversationsClient.create(token);
      twClient.on(CLIENT_EVENTS.CONNECTION_STATE_CHANGE_EVENT, connState => {
        setStatus(connState);
      });

      twClient.on(CLIENT_EVENTS.CONVERSATION_JOINED, conversation => {
        setConversations([...conversationsRef.current, conversation]);
      });
    }

    initTwilioClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onModalSelected = clientId => {
    setClientId(clientId);
    toggleModal();
  };

  const onListSelect = clientId => {
    setClientId(clientId);
  };

  if (loadingLocalChatObjs || status !== "connected") return <Spinner />;

  const selectedConversation = conversations.find(
    it => it.sid === selectedConversationSid
  );
  const clientConvo = data.getManagerChats.find(
    lc => lc.conversationSid === selectedConversationSid
  );
  const clientObj = manager.clients.find(
    c => parseInt(c.id, 10) === clientConvo?.clientId
  );
  const isSelectedReady = selectedConversation && clientConvo && clientObj;

  return (
    <div className={styles.wrapper}>
      <Layout className={styles.layout}>
        <Sider width={300} className={styles.sider}>
          <MessagesList
            header={
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={toggleModal}
                  shape="circle"
                  title="New conversation"
                  icon={<PlusOutlined />}
                />
              </div>
            }
            onSelect={onListSelect}
            selected={selectedConversationSid}
            localChats={data.getManagerChats}
            clients={manager.clients}
            conversations={conversationsRef.current}
          />
        </Sider>
        <Content className={styles.content}>
          {!isSelectedReady && (
            <Centered>
              <Result
                icon={<MessageTwoTone />}
                title="No conversation selected"
              />
            </Centered>
          )}
          {isSelectedReady && (
            <Conversation
              conversationProxy={selectedConversation}
              myIdentity={clientConvo.managerIdentity}
              client={clientObj}
            />
          )}
        </Content>
      </Layout>
      <SelectMessageModal
        isVisible={modalVisible}
        toggle={toggleModal}
        manager={manager}
        callback={onModalSelected}
      />
    </div>
  );
};

MessagingCenter.propTypes = {
  manager: PropTypes.object.isRequired
};

export { MessagingCenter };
