const CLIENT_EVENTS = {
  CONNECTION_STATE_CHANGE_EVENT: "connectionStateChanged",
  CONVERSATION_JOINED: "conversationJoined",
  MESSAGE_ADDED: "messageAdded"
};

const CONNECTION_STATES = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTING: "disconnecting",
  DISCONNECTED: "disconnected",
  DENIED: "denied"
};

export { CLIENT_EVENTS, CONNECTION_STATES };
