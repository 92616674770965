import React from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MessageOutlined } from "@ant-design/icons";

import classes from "./clientsList.module.scss";

const ClientsListRowActions = ({ client }) => {
  return (
    <Col flex="150px" className={classes.clientsListRowClientActions}>
      <Row
        gutter={{ md: 8, lg: 16 }}
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {/* <Col
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button
            size="large"
            type="primary"
            shape="circle"
            title="Video call with client"
            icon={<VideoCameraOutlined />}
          />
        </Col> */}
        <Col
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Link to={`/messaging?clientId=${client.id}`}>
            <Button
              size="large"
              type="primary"
              shape="circle"
              title="Chat with client"
              icon={<MessageOutlined />}
            />
          </Link>
        </Col>
      </Row>
    </Col>
  );
};

ClientsListRowActions.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientsListRowActions };
