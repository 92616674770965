import React from "react";
import { Col, Avatar, Typography } from "antd";
import PropTypes from "prop-types";

import { MeetingService, PACES } from "core/services/meetings";

import classes from "./clientsList.module.scss";

const { Text } = Typography;

const getTextForPaceTag = pace => {
  switch (pace) {
    case PACES.REQ_MEETINGS_EXCEEDING:
      return "Exceeding this month";
    case PACES.REQ_MEETINGS_ON_TARGET:
      return "On target this month";
    default:
      return "Behind this month";
  }
};

const getClassForPaceTag = pace => {
  switch (pace) {
    case PACES.REQ_MEETINGS_EXCEEDING:
      return classes.exceeding;
    case PACES.REQ_MEETINGS_ON_TARGET:
      return classes.ontarget;
    default:
      return classes.behind;
  }
};

const ClientsListRowClient = ({ client }) => {
  const pace = MeetingService.getMeetingPace(
    client.meetingsAttended,
    client.meetingsRequiredPerMonth
  );

  return (
    <Col flex="2" className={classes.clientsListRowClientName}>
      <Avatar
        size={54}
        src={client.photoUrl}
        alt="User Avatar"
        className={classes.clientsListRowAvatar}
      />
      <div className={classes.clientsListRowClientNameText}>
        <Text strong>{`${client.firstName} ${client.lastName}`}</Text>
        <Text
          strong
          className={[
            classes.clientsListRowClientStatus,
            getClassForPaceTag(pace)
          ]}
        >
          {getTextForPaceTag(pace)}
        </Text>
      </div>
    </Col>
  );
};

ClientsListRowClient.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientsListRowClient };
