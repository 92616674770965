import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Button } from "antd";
import { useMutation } from "@apollo/client";

import classes from "./clientDetailNotesForm.module.scss";
import { createManagerNote } from "core/graphql/mutations";
import { managerNotesQuery } from "core/graphql/queries";

const ClientDetailNotesForm = ({ clientId }) => {
  const [form] = Form.useForm();
  const [addNote] = useMutation(createManagerNote);
  const onFinish = values => {
    const { note } = values;
    if (!note.trim()) return;
    const queryOptions = {
      variables: {
        clientId,
        note
      }
    };
    addNote({
      ...queryOptions,
      refetchQueries: [{ query: managerNotesQuery, variables: { clientId } }]
    }).catch(e => console.error(e.message));
    form.resetFields();
  };

  return (
    <div className={classes.container}>
      <Form name="notes" layout="inline" form={form} onFinish={onFinish}>
        <Form.Item
          name="note"
          rules={[{ required: true, message: "Note text is required" }]}
          style={{ flex: 1 }}
        >
          <Input placeholder="Write note here..." />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ClientDetailNotesForm.propTypes = {
  clientId: PropTypes.number.isRequired
};

export { ClientDetailNotesForm };
