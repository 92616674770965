import React, { useState } from "react";
import { Card, Divider, Result } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Logo from "assets/tbv-logo-full-color-no-tagline.svg";
import { getClientInviteQuery } from "core/graphql/queries";
import { Spinner } from "components/util/Spinner";
import { ClientInviteForm } from "./ClientInviteForm";
import { createClient } from "core/graphql/mutations";
import { auth, ERROR_CODE_MESSAGES } from "core/services/firebase";

import styles from "./clientInvitePage.module.scss";

const ClientInvitePage = () => {
  const { token } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [createClientMut] = useMutation(createClient);
  const { loading, data, error } = useQuery(getClientInviteQuery, {
    variables: {
      token
    }
  });

  if (loading) return <Spinner />;

  const onSubmit = async clientDto => {
    let authUser;
    setIsSubmitting(true);
    const { password, ...vars } = clientDto;

    // Create firebase user
    try {
      authUser = await auth.createUserWithEmailAndPassword(
        vars.email,
        password
      );
    } catch (error) {
      setErrorMessage(
        ERROR_CODE_MESSAGES[error.code] || ERROR_CODE_MESSAGES.UNKNOWN
      );
      setIsSubmitting(false);
      return;
    }

    // Create client by accepting invite
    try {
      const { user } = authUser;
      const variables = {
        ...vars,
        authProviderId: user.uid
      };
      const result = await createClientMut({ variables });
      if (result.errors && result.errors.length) {
        // if fail -> delete firebase user
        await user.delete();
        setErrorMessage(result.errors[0].message);
        setIsSubmitting(false);
      } else {
        auth.signOut();
        setSuccess(true);
      }
    } catch (error) {
      await authUser.user.delete();
      setErrorMessage(error.message);
      setErrorMessage(ERROR_CODE_MESSAGES.UNKNOWN);
      return;
    }
  };

  return (
    <Card style={{ borderRadius: ".5rem" }}>
      <div style={{ textAlign: "center", marginBottom: "2rem" }}>
        <img src={Logo} alt="logo" style={{ width: "10rem" }} />
      </div>
      <Divider />
      {success && (
        <Result
          status="success"
          title={
            <div className={styles.inviteSuccessText}>
              Successfully accepted invite, please download the app to log in
            </div>
          }
          className={styles.inviteResult}
        />
      )}

      {error && (
        <Result
          status="warning"
          title={
            <div className={styles.inviteErrorText}>
              Invite expired or invalid, please contact your manager
            </div>
          }
          className={styles.inviteResult}
        />
      )}
      {!error && !success && (
        <ClientInviteForm
          inviteData={data.getInvite}
          onSubmitFn={onSubmit}
          isSubmitting={isSubmitting}
          errorMessage={errorMessage}
        />
      )}
    </Card>
  );
};

export { ClientInvitePage };
