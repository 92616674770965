import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Input,
  Select,
  TimePicker,
  Row,
  Col,
  Button,
  Divider,
  Switch
} from "antd";

import { PinLocationInput } from "./PinLocationInput";
import { AddressInput } from "./AddressInput";
import { weekDayMappings, meetingTypeMappings } from "core/constants/meeting";
import {
  mapInputToMeetingDto,
  mapMeetingToInitialValues
} from "./meetingForm.utils";

const { RangePicker } = TimePicker;

const MeetingForm = ({
  onFormSubmit,
  isSubmitting,
  submitBtnText,
  initialValues = {}
}) => {
  const [meetingForm] = Form.useForm();
  const [address, setAddress] = useState(initialValues.address || {});
  const [hasVirtual, setHasVirtual] = useState(
    initialValues.allowsVirtual || false
  );
  const [location, setLocation] = useState(initialValues.location || {});
  const isAddressValid = useMemo(
    () => address.street && address.city && address.state && address.postalCode,
    [address]
  );
  const formInitialValues = useMemo(
    () => mapMeetingToInitialValues(initialValues),
    [initialValues]
  );

  const onSubmit = async values => {
    const input = {
      ...values,
      ...address,
      ...location
    };
    onFormSubmit(mapInputToMeetingDto(input));
  };
  const onHasVirtualChange = value => setHasVirtual(value);

  return (
    <Form
      layout="vertical"
      name="meetingForm"
      onFinish={onSubmit}
      initialValues={formInitialValues}
      form={meetingForm}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Meeting name"
            name="name"
            rules={[{ required: true, message: "Meeting name is required" }]}
          >
            <Input placeholder="ex. Saturday Night Speakers" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="type"
            label="Meeting type"
            rules={[{ required: true, message: "Meeting type is required" }]}
          >
            <Select placeholder="Select a type...">
              {meetingTypeMappings.map(mt => (
                <Select.Option key={mt.value} value={mt.value}>
                  {mt.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Meeting time"
            name="meetingTime"
            rules={[{ required: true, message: "Meeting time is required" }]}
          >
            <RangePicker
              style={{ width: "100%" }}
              use12Hours
              minuteStep={15}
              format="h:mm a"
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name="weekDay"
            label="Day of week"
            rules={[{ required: true, message: "Meeting day is required" }]}
          >
            <Select placeholder="Choose day">
              {weekDayMappings.map(wd => (
                <Select.Option key={wd.value} value={wd.value}>
                  {wd.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Meeting location"
            name="meetingLocation"
            rules={[
              () => ({
                required: true,
                validator: (_, value) => {
                  const isValid = value && value.trim() && isAddressValid;
                  if (isValid) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Valid meeting location is required");
                  }
                }
              })
            ]}
          >
            <AddressInput setAddress={setAddress} setLocation={setLocation} />
          </Form.Item>
        </Col>
      </Row>
      {location.lat && location.lng && (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Adjust pin location"
              name="pin"
              help="Drag the building icon to adjust the location. The green circle represents the allowed check-in / out area"
            >
              <PinLocationInput location={location} setLocation={setLocation} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="allowsVirtual"
            label="Online meeting available?"
            valuePropName="checked"
          >
            <Switch onChange={onHasVirtualChange} />
          </Form.Item>
        </Col>
      </Row>
      {hasVirtual && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Online meeting url"
              name="virtualUrl"
              rules={[
                { required: hasVirtual, message: "Meeting url is required" },
                { type: "url", message: "Valid url is required" }
              ]}
            >
              <Input placeholder="https://us02web.zoom.us/j/xxxxxxxxxxx" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Meeting password" name="meetingPassword">
              <Input placeholder="Optional" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {submitBtnText}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

MeetingForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitBtnText: PropTypes.string.isRequired,
  initialValues: PropTypes.object
};

export { MeetingForm };
