import React from "react";
import PropTypes from "prop-types";
import { Avatar, Card, Divider, Space, Typography } from "antd";

const { Title, Text } = Typography;

const ManagerProfileSidebar = ({ manager }) => {
  return (
    <Card bodyStyle={{ padding: "1rem", textAlign: "center" }}>
      <Avatar
        style={{ marginTop: 24 }}
        size={86}
        alt="Manager Avatar"
      >{`${manager.firstName[0].toUpperCase()}${manager.lastName[0].toUpperCase()}`}</Avatar>
      <Title
        style={{ marginTop: 24 }}
        level={4}
      >{`${manager.firstName} ${manager.lastName}`}</Title>
      <Space direction="vertical">
        <Text style={{ wordBreak: "break-word" }}>{manager.email}</Text>
        <Text>{manager.phone}</Text>
      </Space>
      {manager.organization && (
        <>
          <Divider />
          <Space direction="vertical">
            <Text strong>Organization</Text>
            <Text>{manager.organization.name}</Text>
          </Space>
        </>
      )}
    </Card>
  );
};

ManagerProfileSidebar.propTypes = {
  manager: PropTypes.object.isRequired
};

export { ManagerProfileSidebar };
