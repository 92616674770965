import moment from "moment-timezone";

const CLIENT_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
};
const PACES = {
  REQ_MEETINGS_ON_TARGET: "ON_TARGET",
  REQ_MEETINGS_BEHIND: "BEHIND",
  REQ_MEETINGS_EXCEEDING: "EXCEEDING"
};

class MeetingService {
  static getMeetingPace(
    attended,
    required,
    endDate = undefined,
    currentDate = undefined
  ) {
    const meetingsLeft = required - attended;
    let daysLeft = 0;

    if (endDate) {
      daysLeft = moment(endDate).diff(moment(currentDate), "days");
    } else {
      daysLeft = moment(currentDate)
        .endOf("month")
        .diff(moment(currentDate), "days");
    }

    if (meetingsLeft < 0) {
      return PACES.REQ_MEETINGS_EXCEEDING;
    } else if (meetingsLeft === required || meetingsLeft > daysLeft) {
      return PACES.REQ_MEETINGS_BEHIND;
    } else {
      return PACES.REQ_MEETINGS_ON_TARGET;
    }
  }

  static getMeetingPaceCountsForActiveClients(
    clients,
    endDate = undefined,
    currentDate = undefined
  ) {
    let paceStats = {
      [PACES.REQ_MEETINGS_BEHIND]: 0,
      [PACES.REQ_MEETINGS_EXCEEDING]: 0,
      [PACES.REQ_MEETINGS_ON_TARGET]: 0
    };

    clients
      .filter(c => c.status === CLIENT_STATUS.ACTIVE)
      .forEach(client => {
        let pace = this.getMeetingPace(
          client.meetingsAttended,
          client.meetingsRequiredPerMonth,
          endDate,
          currentDate
        );
        paceStats[pace] += 1;
      });
    return paceStats;
  }

  static getActiveClientsByPace = (
    clients,
    chosenPace,
    currentDate = undefined
  ) => {
    if (!chosenPace) {
      return clients.filter(c => c.status === CLIENT_STATUS.ACTIVE);
    }
    return clients.filter(c => {
      const pace = this.getMeetingPace(
        c.meetingsAttended,
        c.meetingsRequiredPerMonth,
        moment(currentDate).endOf("month"),
        currentDate
      );
      return c.status === CLIENT_STATUS.ACTIVE && pace === chosenPace;
    });
  };
}

export { MeetingService, PACES, CLIENT_STATUS };
