import React from "react";
import PropTypes from "prop-types";
import { Button, Card, Form, Input, message } from "antd";
import { MaskedInput } from "antd-mask-input";

const layout = {
  labelCol: { xl: { span: 4 }, lg: { span: 6 } },
  wrapperCol: { xl: { span: 8 }, lg: { span: 16 } }
};

const tailLayout = {
  wrapperCol: { xl: { offset: 4, span: 8 }, lg: { offset: 6, span: 16 } }
};

const ManagerProfileContactInfo = ({ manager, updateManager }) => {
  const contactInitialValues = {
    firstName: manager.firstName,
    lastName: manager.lastName,
    phone: manager.phone
  };

  const onSubmitContact = async values => {
    const { firstName, lastName, phone } = values;
    const variables = {
      firstName,
      lastName,
      phone,
      email: manager.email
    };
    try {
      await updateManager({ variables });
      message.success("Contact info updated");
    } catch (e) {
      console.error(e);
      message.error("Oops, there was a problem");
    }
  };

  return (
    <Card title="Contact Info" style={{ marginBottom: 16 }}>
      <Form
        {...layout}
        name="contact"
        initialValues={contactInitialValues}
        onFinish={onSubmitContact}
      >
        <Form.Item
          name="firstName"
          label="First name"
          rules={[{ required: true, message: "First name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          rules={[{ required: true, message: "Last name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone number"
          rules={[{ required: true, message: "Phone number is required" }]}
        >
          <MaskedInput mask="(111) 111-1111" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

ManagerProfileContactInfo.propTypes = {
  manager: PropTypes.object.isRequired,
  updateManager: PropTypes.func.isRequired
};

export { ManagerProfileContactInfo };
