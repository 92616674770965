import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Calendar, Typography, Space } from "antd";
import PropTypes from "prop-types";
import * as moment from "moment-timezone";

import { AttendanceService } from "core/services/attendance";
import { ellipse } from "core/services/format";

import classes from "../clientDetailPage.module.scss";

const { Text } = Typography;

const ClientDetailCalendar = ({ client }) => {
  const history = useHistory();
  const RANGE = [moment().startOf("month"), moment().endOf("month")];

  const attendances = client.currentMonthAttendance.reduce((pv, curr) => {
    const date = moment(curr.checkIn).date();
    const toAdd = {
      id: curr.id,
      status: curr.validation.status,
      meetingName: curr.meeting.name
    };

    if (pv[date]) {
      pv[date].push(toAdd);
    } else {
      pv[date] = [toAdd];
    }

    return pv;
  }, {});

  const onSelect = date => {
    if (!attendances[date.date()]) return false;
    const start = date.format("MM/DD/YYYY");
    const end = date.add(1, "day").format("MM/DD/YYYY");
    const url = `/client/${client.id}/attendance?start=${start}&end=${end}`;
    return history.push(url);
  };

  const dateCellRender = value => {
    if (value.month() !== moment().month()) {
      return <></>;
    }
    const date = value.date();
    const data = attendances[date];
    if (data) {
      return (
        <ul className={classes.attendanceDay}>
          {data.map(item => (
            <li
              key={item.content}
              className={classes.attendanceDayItem}
              style={{ marginTop: 2 }}
            >
              <Space>
                <img
                  src={AttendanceService.getAttendanceIcon(item.status)}
                  alt={item.meetingName}
                />
                <Text style={{ fontSize: "0.8rem" }}>
                  {item.meetingName.length < 13
                    ? item.meetingName
                    : ellipse(item.meetingName)}
                </Text>
              </Space>
            </li>
          ))}
        </ul>
      );
    }
    return <></>;
  };

  return (
    <Card title={`Attendance for ${moment().format("MMMM")}`}>
      <Calendar
        validRange={RANGE}
        dateCellRender={dateCellRender}
        mode="month"
        /** Remove controls */
        headerRender={() => <></>}
        onSelect={onSelect}
      />
    </Card>
  );
};

ClientDetailCalendar.propTypes = {
  client: PropTypes.object.isRequired
};

export { ClientDetailCalendar };
