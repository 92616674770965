// mobile app verification values
export const FINGERPRINT = "FINGERPRINT";
export const FACE = "FACE";
export const IRIS = "IRIS";
export const HAND = "HAND";
export const TOUCH = "TOUCH";

// react app localized biometric value types
export const BIO_VERIFY_TOUCH = "Fingerprint";
export const BIO_VERIFY_FACE = "Face";
export const BIO_VERIFY_EYE = "Eye";

export const getBioVerifyType = bioMetricType => {
  if (bioMetricType) {
    switch (bioMetricType.toUpperCase()) {
      case FINGERPRINT:
        return BIO_VERIFY_TOUCH;
      case HAND:
        return BIO_VERIFY_TOUCH;
      case FACE:
        return BIO_VERIFY_FACE;
      case IRIS:
        return BIO_VERIFY_EYE;
      case TOUCH:
        return BIO_VERIFY_TOUCH;
      default:
        return bioMetricType.toUpperCase();
    }
  }
  return "None";
};

export const ATTENDANCE_INVALID_REASONS = {
  LEFT_EARLY: "LEFT_EARLY",
  ARRIVED_LATE: "ARRIVED_LATE",
  NO_BIOMETRICS_CHECKIN: "NO_BIOMETRICS_CHECKIN",
  NO_BIOMETRICS_CHECKOUT: "NO_BIOMETRICS_CHECKOUT",
  NO_LOCATION_CHECKIN: "NO_LOCATION_CHECKIN",
  NO_LOCATION_CHECKOUT: "NO_LOCATION_CHECKOUT",
  DURATION_TOO_SHORT: "DURATION_TOO_SHORT",
  INVALID_LOCATION_CHECKIN: "INVALID_LOCATION_CHECKIN",
  INVALID_LOCATION_CHECKOUT: "INVALID_LOCATION_CHECKOUT",
  NO_SUCH_MEETING: "NO_SUCH_MEETING",
  MEETING_PENDING: "MEETING_PENDING"
};

export const LEFT_EARLY = "Left Early";
export const ARRIVED_LATE = "Arrived Late";
export const NO_BIOMETRICS_CHECKOUT = "No Biometrics check-out";
export const NO_BIOMETRICS_CHECKIN = "No Biometrics check-in";
export const NO_LOCATION_CHECKIN = "No location check-in";
export const NO_LOCATION_CHECKOUT = "No location check-out";
export const DURATION_TOO_SHORT = "Stay too short";
export const INVALID_LOCATION_CHECKIN = "Too far check-in";
export const INVALID_LOCATION_CHECKOUT = "Too far check-out";
export const NO_SUCH_MEETING = "This meeting does not exist";
export const MEETING_PENDING = "This meeting is still pending verification";

export const getAttendanceReasonMsg = reason => {
  switch (reason) {
    case ATTENDANCE_INVALID_REASONS.LEFT_EARLY:
      return LEFT_EARLY;
    case ATTENDANCE_INVALID_REASONS.ARRIVED_LATE:
      return ARRIVED_LATE;
    case ATTENDANCE_INVALID_REASONS.NO_BIOMETRICS_CHECKIN:
      return NO_BIOMETRICS_CHECKIN;
    case ATTENDANCE_INVALID_REASONS.NO_BIOMETRICS_CHECKOUT:
      return NO_BIOMETRICS_CHECKOUT;
    case ATTENDANCE_INVALID_REASONS.NO_LOCATION_CHECKIN:
      return NO_LOCATION_CHECKIN;
    case ATTENDANCE_INVALID_REASONS.NO_LOCATION_CHECKOUT:
      return NO_LOCATION_CHECKOUT;
    case ATTENDANCE_INVALID_REASONS.DURATION_TOO_SHORT:
      return DURATION_TOO_SHORT;
    case ATTENDANCE_INVALID_REASONS.INVALID_LOCATION_CHECKIN:
      return INVALID_LOCATION_CHECKIN;
    case ATTENDANCE_INVALID_REASONS.INVALID_LOCATION_CHECKOUT:
      return INVALID_LOCATION_CHECKOUT;
    default:
      return reason;
  }
};
