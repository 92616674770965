import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { message } from "antd";

import { createClientConversation } from "core/graphql/mutations";
import { managerChatsQuery } from "core/graphql/queries";

const useGetOrSetConversation = (
  callback,
  clientId,
  clients,
  localConversations
) => {
  const [createConversation] = useMutation(createClientConversation);
  useEffect(() => {
    async function create() {
      if (clients.find(c => c.id === clientId)) {
        try {
          const result = await createConversation({
            variables: { input: { clientId } },
            refetchQueries: [{ query: managerChatsQuery }]
          });
          callback(result.data?.createManagerChat?.conversationSid);
        } catch (error) {
          message.error(error.message);
        }
      }
    }
    if (clientId && localConversations) {
      const clientConvo = localConversations.find(
        lc => lc.clientId === parseInt(clientId, 10)
      );
      if (clientConvo) {
        callback(clientConvo.conversationSid);
      } else {
        create();
      }
    }
  }, [clientId, localConversations, clients, callback, createConversation]);
};

export { useGetOrSetConversation };
