import React from "react";
import { ApolloProvider } from "@apollo/client";

import { Routes } from "core/routes";
import { client } from "core/graphql/client";
import { AuthProvider } from "core/providers/AuthProvider";
import ErrorBoundary from "components/util/ErrorBoundary";

function App() {
  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ErrorBoundary>
    </ApolloProvider>
  );
}

export default App;
