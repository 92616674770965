import React from "react";
import PropTypes from "prop-types";
import { Badge, Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import {
  ShopOutlined,
  UserOutlined,
  TeamOutlined,
  LogoutOutlined,
  MessageOutlined,
  PieChartOutlined,
  UserAddOutlined,
  MailOutlined,
  HomeOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";

import { auth } from "core/services/firebase";
import { ellipse } from "core/services/format";

const ManagerNavigation = ({ manager, setUserLoading }) => {
  const location = useLocation();
  const history = useHistory();

  const onMenuItemClick = e => {
    if (e.key === "logout") {
      setUserLoading(false);
      return auth.signOut();
    }
    return history.push(e.key);
  };

  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[location.pathname]}
      selectedKeys={[location.pathname]}
      mode="inline"
      onClick={onMenuItemClick}
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <Menu.Item key="/overview">
        <PieChartOutlined />
        <span>Overview</span>
      </Menu.Item>
      <SubMenu
        title={
          <span>
            <TeamOutlined />
            <span>Clients</span>
          </span>
        }
      >
        <Menu.Item key="/client/invite">
          <UserAddOutlined />
          <span>Add new client</span>
        </Menu.Item>
        <Menu.Item key="/pending-invites">
          <MailOutlined />
          <span>Pending invites</span>
          <Badge
            style={{ marginLeft: 5, marginTop: 5 }}
            dot
            count={manager.clientInvites.length}
          />
        </Menu.Item>
      </SubMenu>
      {manager.organization && (
        <SubMenu
          title={
            <span>
              <ShopOutlined />
              <span>{ellipse(manager.organization.name, 20)}</span>
            </span>
          }
        >
          <Menu.Item key="/org/meetings/create">
            <PlusOutlined />
            <span>Add meeting</span>
          </Menu.Item>
          <Menu.Item key="/org/meetings">
            <HomeOutlined />
            <span>Meetings</span>
          </Menu.Item>
        </SubMenu>
      )}
      <Menu.Item key="/messaging">
        <MessageOutlined />
        <span>Messaging</span>
      </Menu.Item>
      <Menu.Item style={{ marginTop: "auto" }} key="/profile">
        <UserOutlined />
        <span>{`${manager.firstName} ${manager.lastName}`}</span>
      </Menu.Item>
      <Menu.Item key="logout" onClick={auth.doSignOut}>
        <LogoutOutlined />
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
};

ManagerNavigation.propTypes = {
  manager: PropTypes.object.isRequired,
  setUserLoading: PropTypes.func.isRequired
};

export { ManagerNavigation };
