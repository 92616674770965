import * as moment from "moment-timezone";

import { ATTENDANCE_STATUS } from "core/services/attendance";

export const getAttendanceTableTitle = (
  start,
  end,
  status,
  format = "dddd, MMMM Do YYYY"
) => {
  const startDate = moment(start),
    endDate = moment(end),
    prefix = status
      ? `${status[0] + status.toLowerCase().slice(1)} attendance for`
      : "Attendance for";

  if (endDate.diff(startDate, "days") < 2) {
    return `${prefix} ${startDate.format(format)}`;
  }

  return `${prefix} ${startDate.format(format)} - ${endDate.format(format)}`;
};

export const getValidationStatuses = statuses =>
  statuses.reduce((acc, currStatus) => {
    const val = ATTENDANCE_STATUS[currStatus.toUpperCase().trim()];
    if (val) {
      acc.push(val);
    }
    return acc;
  }, []);

export const getBatteryPercentage = battery => {
  if (parseFloat(battery)) {
    return battery > 0 ? `${battery * 100}%` : "N/A";
  }
  return "N/A";
};

export const drawAttendanceOnMap = (map, maps, attendance) => {
  const RADIUS_METERS = 100;

  const hasCheckInLocation =
    attendance.checkInVerification &&
    attendance.checkInVerification.lat &&
    attendance.checkInVerification.lng;
  const hasCheckOutLocation =
    attendance.checkOutVerification &&
    attendance.checkOutVerification.lat &&
    attendance.checkOutVerification.lng;

  // Add points to boundry to get center and zoom
  const checkInLocation = hasCheckInLocation
    ? {
        lat: attendance.checkInVerification.lat,
        lng: attendance.checkInVerification.lng
      }
    : null;
  const checkOutLocation = hasCheckOutLocation
    ? {
        lat: attendance.checkOutVerification.lat,
        lng: attendance.checkOutVerification.lng
      }
    : null;
  const bounds = new maps.LatLngBounds();
  const loc = new maps.LatLng(
    attendance.meeting.location.lat,
    attendance.meeting.location.lng
  );
  bounds.extend(loc);
  if (hasCheckInLocation) {
    bounds.extend(new maps.LatLng(checkInLocation.lat, checkInLocation.lng));
  }
  if (hasCheckOutLocation) {
    bounds.extend(new maps.LatLng(checkOutLocation.lat, checkOutLocation.lng));
  }

  map.fitBounds(bounds); // auto-zoom
  map.panToBounds(bounds); // auto-center

  // Add markers to the map
  const meetingMarker = new maps.Marker({
    icon: require("../../../assets/building.png"),
    position: attendance.meeting.location,
    map,
    title: "Meeting"
  });

  if (hasCheckInLocation) {
    new maps.Marker({
      position: { lat: checkInLocation.lat, lng: checkInLocation.lng },
      map,
      title: "Check-in",
      label: "In"
    });
  }
  if (hasCheckOutLocation) {
    new maps.Marker({
      position: { lat: checkOutLocation.lat, lng: checkOutLocation.lng },
      map,
      title: "Check-out",
      label: "Out"
    });
  }

  // Draw meeting geofence and bind it to meeting marker
  const meetingRadius = new maps.Circle({
    map,
    radius: RADIUS_METERS,
    strokeColor: "#00FF00",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#00FF00",
    fillOpacity: 0.35
  });
  meetingRadius.bindTo("center", meetingMarker, "position");
};
