import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Map from "google-map-react";

const size = { height: "350px", width: "100%" };

const PinLocationInput = ({ location, setLocation }) => {
  // Only capture default on first render
  // eslint-disable-next-line
  const defaultCenter = useMemo(() => location, []);

  const onMapsLoaded = ({ map, maps }) => {
    const RADIUS_METERS = 100;

    // Add markers to the map
    const meetingMarker = new maps.Marker({
      icon: require("../../../assets/building.png"),
      position: location,
      map,
      title: "Meeting",
      draggable: true
    });

    // Draw meeting geofence and bind it to meeting marker
    const meetingRadius = new maps.Circle({
      map,
      radius: RADIUS_METERS,
      strokeColor: "#00FF00",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00FF00",
      fillOpacity: 0.35
    });

    // Bindings
    meetingRadius.bindTo("center", meetingMarker, "position");
    meetingMarker.addListener("dragend", evt =>
      setLocation({ lat: evt.latLng.lat(), lng: evt.latLng.lng() })
    );
  };

  return (
    <div style={size}>
      <Map
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        }}
        options={{ draggable: false }}
        defaultZoom={17}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapsLoaded}
      />
    </div>
  );
};

PinLocationInput.propTypes = {
  location: PropTypes.object.isRequired,
  setLocation: PropTypes.func.isRequired
};

export { PinLocationInput };
