import React from "react";
import PropTypes from "prop-types";
import { Text, View, StyleSheet, Image, Page } from "@react-pdf/renderer";
import * as moment from "moment-timezone";

import { formatPhoneNumber } from "core/services/format";

const styles = StyleSheet.create({
  header: {
    fontSize: 8,
    marginTop: 10,
    textAlign: "center",
    color: "grey"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  coverClientSection: { textAlign: "center", marginTop: 200 },
  coverClientDetails: { fontSize: 10, marginTop: 15 },
  coverClientProgramDetails: {
    fontSize: 10,
    marginTop: 30,
    width: 300,
    marginHorizontal: "auto",
    border: "1 solid #9e9e9e",
    padding: 5
  },
  clientImage: {
    width: 60,
    borderRadius: 30,
    marginHorizontal: "auto",
    marginBottom: 10
  },
  row: {
    display: "flex",
    flexDirection: "row",
    textAlign: "right",
    marginBottom: 5
  }
});

const ClientReportCoverPage = ({ client, manager }) => {
  return (
    <Page size="LETTER">
      <Text style={styles.header} fixed>
        ~ Generated using TBV ~
      </Text>
      <View style={styles.coverClientSection}>
        {client.photoUrl && (
          <Image src={client.photoUrl} style={styles.clientImage} />
        )}
        <Text>{`${client.firstName} ${client.lastName}`}</Text>
        <View style={styles.coverClientDetails}>
          <Text>{formatPhoneNumber(client.phone)}</Text>
          <Text>{client.email}</Text>
        </View>
        <View style={styles.coverClientProgramDetails}>
          <View style={styles.row}>
            <Text style={{ flex: 2 }}>Program:</Text>
            <Text style={{ flex: 3 }}>{client.organization.name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ flex: 2 }}>Supervisor:</Text>
            <Text
              style={{ flex: 3 }}
            >{`${manager.firstName} ${manager.lastName}`}</Text>
          </View>
          <View style={styles.row}>
            <Text style={{ flex: 2 }}>Start date:</Text>
            <Text style={{ flex: 3 }}>
              {moment(client.createdAt).format("MMMM Do YYYY")}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={{ flex: 2 }}>Anticipated completion:</Text>
            <Text style={{ flex: 3 }}>
              {moment(client.createdAt)
                .add(client.durationDays, "days")
                .format("MMMM Do YYYY")}
            </Text>
          </View>
        </View>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
};

ClientReportCoverPage.propTypes = {
  client: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
};

export { ClientReportCoverPage };
