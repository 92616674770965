import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Select, Modal, Avatar, message } from "antd";

import { CLIENT_STATUS } from "core/services/meetings";
import { createClientConversation } from "core/graphql/mutations";
import { managerChatsQuery } from "core/graphql/queries";

const SelectMessageModal = ({ isVisible, toggle, manager, callback }) => {
  const [selected, setSelected] = useState(undefined);
  const [creating, setCreating] = useState(false);
  const [createConversation] = useMutation(createClientConversation);
  const clients = manager.clients.filter(
    c => c.status === CLIENT_STATUS.ACTIVE
  );

  const onSubmit = async () => {
    if (!selected) return;
    const clientId = selected;
    try {
      setCreating(true);
      const result = await createConversation({
        variables: { input: { clientId } },
        refetchQueries: [{ query: managerChatsQuery }]
      });
      const id = result.data?.createManagerChat?.clientId;
      callback(id);
      setCreating(false);
    } catch (error) {
      if (error.message === "Conversation already exists") {
        message.error(error.message);
      } else {
        message.error("Error creating conversation");
      }
      setCreating(false);
    }
  };

  return (
    <Modal
      title="New conversation"
      visible={isVisible}
      onCancel={toggle}
      onOk={onSubmit}
      okButtonProps={{ loading: creating }}
    >
      <Select
        size="large"
        style={{ minWidth: "70%" }}
        value={selected}
        placeholder="Choose a client..."
        onChange={val => setSelected(val)}
      >
        {clients.map(c => (
          <Select.Option
            key={c.id}
            value={c.id}
            style={{ padding: "10px 5px" }}
          >
            <Avatar style={{ marginRight: 5 }} src={c.photoUrl} />{" "}
            {`${c.firstName} ${c.lastName}`}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

SelectMessageModal.propTypes = {
  manager: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};

export { SelectMessageModal };
