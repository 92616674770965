import React from "react";
import { Spin } from "antd";

import { Centered } from "./Centered";

const Spinner = () => {
  return (
    <Centered>
      <Spin size="large" />
    </Centered>
  );
};

export { Spinner };
