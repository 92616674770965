import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import * as moment from "moment-timezone";
import { Button, Card, Col, Form, Row, DatePicker, Select, Tag } from "antd";

import { Page } from "components/layout/Page";
import { getClientQuery } from "core/graphql/queries";
import { Spinner } from "components/util/Spinner";
import {
  ATTENDANCE_STATUS,
  ATTENDANCE_STATUS_TAG_COLOR
} from "core/services/attendance";
import { ClientReport } from "./report/ClientReport";

const { RangePicker } = DatePicker;

const ConfigureClientReportPage = ({ manager }) => {
  const { id } = useParams();
  const [showReport, setShowReport] = useState(false);
  const [reportConfig, setReportConfig] = useState(null);
  const [configureForm] = Form.useForm();
  const queryOptions = {
    variables: {
      id
    }
  };
  const { loading, data } = useQuery(getClientQuery, queryOptions);

  if (loading) {
    return <Spinner />;
  }

  const client = data.user;
  const defaultStartDate = moment(client.createdAt);
  const defaultEndDate = moment();

  const disabledDates = current => {
    return current < defaultStartDate;
  };

  const PRESET_RANGES = {
    "Supervision Term": [defaultStartDate, defaultEndDate],
    "Last Month": [
      moment()
        .startOf("month")
        .subtract(1, "month"),
      moment()
        .endOf("month")
        .subtract(1, "month")
    ],
    "This Month": [moment().startOf("month"), moment().endOf("month")]
  };

  const initalValues = {
    dateRange: [defaultStartDate, defaultEndDate]
  };

  const submit = async values => {
    const { validationStatuses, dateRange } = values;
    const reportCfg = {
      variables: {
        clientId: client.id,
        startDate: dateRange[0].format("MM/DD/YYYY"),
        endDate: dateRange[1].format("MM/DD/YYYY"),
        validationStatuses
      }
    };
    setReportConfig(reportCfg);
    setShowReport(true);
  };

  const closeReport = () => {
    setShowReport(false);
    setReportConfig(null);
  };

  const PAGE_TITLE = `Configure client report for ${client.firstName} ${client.lastName}`;

  return (
    <>
      {showReport && (
        <ClientReport
          reportConfig={reportConfig}
          client={client}
          manager={manager}
          closeReport={closeReport}
        />
      )}

      {!showReport && (
        <Page title={PAGE_TITLE}>
          <Col
            xxl={{ span: 10, offset: 7 }}
            xl={{ span: 12, offset: 6 }}
            sm={{ span: 18, offset: 3 }}
            xs={{ span: 24 }}
          >
            <Card title={PAGE_TITLE}>
              <Form
                layout="vertical"
                name="configureReport"
                onFinish={submit}
                form={configureForm}
                initialValues={initalValues}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="dateRange"
                      label="Date range"
                      rules={[
                        {
                          type: "array",
                          required: true,
                          message: "Please select a range"
                        }
                      ]}
                    >
                      <RangePicker
                        format={"MM/DD/YYYY"}
                        ranges={PRESET_RANGES}
                        disabledDate={disabledDates}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="validationStatuses" label="Meeting status">
                      <Select
                        mode="multiple"
                        style={{ maxWidth: "20rem" }}
                        placeholder="Filter by status"
                        optionLabelProp="label"
                      >
                        <Select.Option
                          value={ATTENDANCE_STATUS.PENDING}
                          label="Pending"
                        >
                          <Tag
                            color={
                              ATTENDANCE_STATUS_TAG_COLOR[
                                ATTENDANCE_STATUS.PENDING
                              ]
                            }
                          >
                            {ATTENDANCE_STATUS.PENDING}
                          </Tag>
                        </Select.Option>
                        <Select.Option
                          value={ATTENDANCE_STATUS.VERIFIED}
                          label="Verified"
                        >
                          <Tag
                            color={
                              ATTENDANCE_STATUS_TAG_COLOR[
                                ATTENDANCE_STATUS.VERIFIED
                              ]
                            }
                          >
                            {ATTENDANCE_STATUS.VERIFIED}
                          </Tag>
                        </Select.Option>
                        <Select.Option
                          value={ATTENDANCE_STATUS.UNVERIFIED}
                          label="Unverified"
                        >
                          <Tag
                            color={
                              ATTENDANCE_STATUS_TAG_COLOR[
                                ATTENDANCE_STATUS.UNVERIFIED
                              ]
                            }
                          >
                            {ATTENDANCE_STATUS.UNVERIFIED}
                          </Tag>
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Generate report
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Page>
      )}
    </>
  );
};

ConfigureClientReportPage.propTypes = {
  manager: PropTypes.object.isRequired
};

export { ConfigureClientReportPage };
