import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { groupBy, orderBy, keys } from "lodash";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import * as moment from "moment-timezone";

import { ClientReportCoverPage } from "./ClientReportCoverPage";
import { ClientReportAttendanceTable } from "./ClientReportAttendanceTable";

const styles = StyleSheet.create({
  header: {
    fontSize: 8,
    marginTop: 10,
    textAlign: "center",
    color: "grey"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 8,
    marginHorizontal: 20,
    paddingBottom: 2,
    borderBottom: "2pt solid black"
  },
  stats: {
    transform: "translateY(6)",
    fontFamily: "Helvetica",
    fontSize: 12
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  }
});

const ClientReportDocument = ({ client, manager, attendance }) => {
  const groupedData = useMemo(() => {
    const ordered = orderBy(attendance, "checkin", "desc");
    const grouped = groupBy(ordered, att =>
      moment(att.checkIn).format("MMMM YYYY")
    );
    return grouped;
  }, [attendance]);

  const required = client.meetingsRequiredPerMonth;

  return (
    <Document>
      <ClientReportCoverPage manager={manager} client={client} />
      {keys(groupedData).map(month => {
        return (
          <Page key={month} size="LETTER" orientation="landscape">
            <Text style={styles.header} fixed>
              ~ Generated using TBV ~
            </Text>
            <View style={styles.title}>
              <View style={{ flex: 1 }}>
                <Text style={styles.titleText}>{month}</Text>
              </View>
              <View
                style={{ flex: 1, alignItems: "right", position: "relative" }}
              >
                <Text
                  style={styles.stats}
                >{`${client.firstName} attended ${groupedData[month].length} out of ${required} required meetings`}</Text>
              </View>
            </View>

            <ClientReportAttendanceTable attendance={groupedData[month]} />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        );
      })}
    </Document>
  );
};

ClientReportDocument.propTypes = {
  client: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  attendance: PropTypes.array.isRequired
};

export { ClientReportDocument };
