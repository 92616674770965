import React, { useMemo } from "react";
import { Col, Card, Table } from "antd";
import PropTypes from "prop-types";
import * as moment from "moment-timezone";
import { useQuery } from "@apollo/client";
import {
  useQueryParam,
  StringParam,
  withDefault,
  ArrayParam
} from "use-query-params";

import { getColumns } from "./attendanceTableColumns";
import {
  getAttendanceTableTitle,
  getValidationStatuses
} from "./attendanceTable.utils";
import { AttendanceTableControls } from "./AttendanceTableControls";
import { AttendanceTableRowPanel } from "./AttendanceTableRowPanel";
import { clientsActivityQuery } from "core/graphql/queries";

const AttendanceTable = ({ client }) => {
  const defaultStartDate = moment(client.createdAt).format("MM/DD/YYYY");
  const defaultEndDate = moment().format("MM/DD/YYYY");
  const startDate = useQueryParam(
    "start",
    withDefault(StringParam, defaultStartDate)
  );
  const endDate = useQueryParam(
    "end",
    withDefault(StringParam, defaultEndDate)
  );
  const statuses = useQueryParam("status", withDefault(ArrayParam, []));
  const validationStatuses = getValidationStatuses(statuses[0]);

  const queryOptions = {
    variables: {
      clientId: client.id,
      startDate: moment(startDate[0]).isValid()
        ? startDate[0]
        : defaultStartDate,
      endDate: moment(endDate[0]).isValid() ? endDate[0] : defaultEndDate,
      validationStatuses
    }
  };
  const { loading, data } = useQuery(clientsActivityQuery, queryOptions);

  const TABLE_COLUMNS = useMemo(() => getColumns(client.id, queryOptions), [
    client.id,
    queryOptions
  ]);

  const title = getAttendanceTableTitle(
    startDate[0],
    endDate[0],
    validationStatuses[0]
  );

  return (
    <Col xl={18} lg={16}>
      <Card title={title}>
        <AttendanceTableControls
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          startDate={startDate}
          endDate={endDate}
          statuses={statuses}
        />
        <Table
          loading={loading}
          columns={TABLE_COLUMNS}
          dataSource={data && data.clientsAttendanceByDate}
          pagination={{ pageSize: 15 }}
          rowKey={record => record.id}
          scroll={{ x: true }}
          expandable={{
            // eslint-disable-next-line react/display-name
            expandedRowRender: record => (
              <AttendanceTableRowPanel record={record} />
            ),
            rowExpandable: record => !record.isVirtual
          }}
        />
      </Card>
    </Col>
  );
};

AttendanceTable.propTypes = {
  client: PropTypes.object.isRequired
};

export { AttendanceTable };
