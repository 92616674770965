import * as moment from "moment-timezone";

export const SUPERVISION_DURATIONS = {
  DURATION_MONTHS: "months",
  DURATION_DAYS: "days",
  DURATION_YEARS: "years"
};

export const convertToDaysDuration = (count, unit) => {
  switch (unit) {
    case SUPERVISION_DURATIONS.DURATION_YEARS:
      return moment()
        .add(count, "years")
        .diff(moment(), "days");
    case SUPERVISION_DURATIONS.DURATION_MONTHS:
      return moment()
        .add(count, "months")
        .diff(moment(), "days");
    default:
      return count;
  }
};
