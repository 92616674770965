import React from "react";
import PropTypes from "prop-types";
import { Layout, Row, Col } from "antd";
import { Route } from "react-router-dom";

import classes from "./authLayout.module.scss";

const { Content } = Layout;

const AuthLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout className={classes.authLayoutBg}>
          <Content>
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ minHeight: "100vh" }}
            >
              <Col
                span={6}
                xxl={{ span: 6 }}
                xl={{ span: 8 }}
                sm={{ span: 12 }}
                xs={{ span: 22 }}
              >
                <Component {...matchProps} />
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    />
  );
};

AuthLayout.propTypes = {
  component: PropTypes.func.isRequired
};

export { AuthLayout };
