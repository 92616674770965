export const alphabeticalSort = (a, b) => {
  const word1 = a.toUpperCase(),
    word2 = b.toUpperCase();
  if (word1 < word2) {
    return -1;
  }
  if (word1 > word2) {
    return 1;
  }
  return 0;
};

export const weekDaysMapping = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday"
};

export const parseTime = timeString => {
  if (timeString === "") return null;

  var time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
  if (time === null) return null;

  var hours = parseInt(time[1], 10);
  if (hours === 12 && !time[4]) {
    hours = 0;
  } else {
    hours += hours < 12 && time[4] ? 12 : 0;
  }
  var d = new Date();
  d.setHours(hours);
  d.setMinutes(parseInt(time[3], 10) || 0);
  d.setSeconds(0, 0);
  return d;
};
