const meetingTypeMappings = [
  { label: "Alcoholics Anonymous", value: "AA" },
  { label: "Narcotics Anonymous", value: "NA" },
  { label: "Smart Recovery", value: "SR" },
  { label: "Life Ring", value: "LR" },
  { label: "Y12SR", value: "Y12" },
  { label: "Refuge Recovery", value: "RR" },
  { label: "Celebrate Recovery", value: "CR" },
  { label: "Other", value: "OTHER" }
];
const weekDayMappings = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 7 }
];

export { meetingTypeMappings, weekDayMappings };
