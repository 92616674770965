import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";

import { MessageListItem } from "./MessageListItem";

import styles from "./messagesList.module.scss";

const MessagesList = ({
  conversations,
  header,
  selected,
  onSelect,
  localChats,
  clients
}) => {
  return (
    <List
      size="large"
      className={styles.messagesList}
      header={header || "Messages"}
      bordered={true}
      dataSource={conversations}
      loading={!!localChats.length && conversations.length === 0}
      renderItem={item => (
        <MessageListItem
          conversation={item}
          clients={clients}
          localChats={localChats}
          onSelect={onSelect}
          isSelected={selected === item.sid}
        />
      )}
    />
  );
};

MessagesList.propTypes = {
  conversations: PropTypes.array.isRequired,
  header: PropTypes.node.isRequired,
  onSelect: PropTypes.func.isRequired,
  localChats: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  selected: PropTypes.string
};

export { MessagesList };
