import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { clientsActivityQuery, getClientQuery } from "core/graphql/queries";
import { updateClientAttendance } from "core/graphql/mutations";

const { Option } = Select;

const AttendanceTableVerificationAction = ({
  record,
  clientId,
  currQueryParams
}) => {
  const [updateAttendance] = useMutation(updateClientAttendance);
  const onChange = value => {
    const queryOptions = {
      variables: {
        clientId,
        attendanceId: record.id,
        validationStatus: value
      }
    };
    updateAttendance({
      ...queryOptions,
      refetchQueries: [
        { query: clientsActivityQuery, variables: currQueryParams.variables },
        { query: getClientQuery, variables: { id: clientId } }
      ]
    }).catch(e => console.error(e.message));
  };

  return (
    <div>
      <Select
        defaultValue={record.validation.status}
        style={{ width: 120 }}
        onChange={onChange}
      >
        <Option value="VERIFIED">Verified</Option>
        <Option value="UNVERIFIED">Unverified</Option>
        <Option value="PENDING">Pending</Option>
      </Select>
    </div>
  );
};

AttendanceTableVerificationAction.propTypes = {
  record: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  currQueryParams: PropTypes.object.isRequired
};

export { AttendanceTableVerificationAction };
