import verified from "assets/verified.svg";
import unverified from "assets/unverified.svg";
import pending from "assets/pending.svg";
import notattended from "assets/notattended.svg";

const ATTENDANCE_STATUS = {
  VERIFIED: "VERIFIED",
  UNVERIFIED: "UNVERIFIED",
  PENDING: "PENDING",
  NOT_ATTENDED: "NOT_ATTENDED"
};

const ATTENDANCE_STATUS_TAG_COLOR = {
  VERIFIED: "#2378d6",
  UNVERIFIED: "#d45d5d",
  PENDING: "#f1c40f",
  NOT_ATTENDED: "#bdbdbd"
};

class AttendanceService {
  static getStatusCounts(attendance) {
    return attendance.reduce(
      (p, c) => {
        const status = c.validation.status;
        p[status] = p[status] + 1;
        return p;
      },
      {
        [ATTENDANCE_STATUS.VERIFIED]: 0,
        [ATTENDANCE_STATUS.UNVERIFIED]: 0,
        [ATTENDANCE_STATUS.PENDING]: 0
      }
    );
  }
  static attendanceWithBlanks(attendance, diff) {
    let attendanceWithBlanks;
    const NOT_ATTENDED = {
      validation: {
        status: ATTENDANCE_STATUS.NOT_ATTENDED
      }
    };
    if (diff < 1) {
      attendanceWithBlanks = attendance;
    } else {
      attendanceWithBlanks = new Array(diff).fill(NOT_ATTENDED);
      attendanceWithBlanks.unshift(...attendance);
    }
    return attendanceWithBlanks;
  }
  static getAttendanceIcon(status) {
    switch (status) {
      case ATTENDANCE_STATUS.VERIFIED:
        return verified;
      case ATTENDANCE_STATUS.UNVERIFIED:
        return unverified;
      case ATTENDANCE_STATUS.PENDING:
        return pending;
      default:
        return notattended;
    }
  }
}

export { AttendanceService, ATTENDANCE_STATUS, ATTENDANCE_STATUS_TAG_COLOR };
