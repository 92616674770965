import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input, message } from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import { getAddressFromComponents } from "./addressInput.utils";

import styles from "./addressInput.module.scss";

const AddressInput = ({ value, onChange, setAddress, setLocation }) => {
  const [isGeocoding, setIsGeocoding] = useState(false);

  const handleChange = address => {
    setAddress({});
    setLocation({});
    onChange(address);
  };

  const handleSelect = async address => {
    setIsGeocoding(true);
    try {
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      setIsGeocoding(false);
      setAddress(getAddressFromComponents(results[0].address_components));
      setLocation({ lat, lng });

      onChange(results[0].formatted_address);
    } catch (error) {
      console.error("Error", error);
      message.error("Error geocoding address, please try again");
      setIsGeocoding(false);
    }
  };

  return (
    <PlacesAutocomplete
      value={value || ""}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <>
          <Input
            disabled={isGeocoding}
            {...getInputProps({
              placeholder: "Search for an address or place",
              className: "location-search-input"
            })}
          />
          {!!suggestions.length && (
            <div
              className={[
                "autocomplete-dropdown-container",
                styles.autoCompleteDropdownContainer
              ].join(" ")}
            >
              {loading && (
                <div
                  key="loading"
                  className={["suggestion-item", styles.item].join(" ")}
                >
                  Loading...
                </div>
              )}
              {suggestions.map((suggestion, i) => {
                const className = [
                  suggestion.active && styles.active,
                  styles.item
                ].join(" ");
                return (
                  <div
                    key=""
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      key: i
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </PlacesAutocomplete>
  );
};

AddressInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  setAddress: PropTypes.func,
  setLocation: PropTypes.func
};

export { AddressInput };
