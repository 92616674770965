import { gql } from "@apollo/client";

const AttendanceValidationFragment = gql`
  fragment AttendanceValidationDetail on AttendanceValidation {
    status
    invalidReasons
  }
`;

const AttendanceNoMeetingFragment = gql`
  fragment AttendanceNoMeetingDetail on Attendance {
    id
    checkIn
    checkOut
    status
    notes
    rating
    meetingStartTime
    meetingDurationMins
    checkInVerification {
      lat
      lng
      biometricVerificationType
      photoUrl
    }
    checkOutVerification {
      lat
      lng
      biometricVerificationType
      photoUrl
    }
    validation {
      ...AttendanceValidationDetail
    }
  }
  ${AttendanceValidationFragment}
`;

const UserDetailFragment = gql`
  fragment UserDetail on User {
    id
    email
    firstName
    lastName
    phone
    photoUrl
    address {
      city
      state
      street
      postalCode
    }
    sobrietyDate
    daysSober
    meetingsAttended
    meetingsRequiredPerMonth
    status
    weeklyAttendance {
      mon {
        attended
        count
      }
      tue {
        attended
        count
      }
      wed {
        attended
        count
      }
      thu {
        attended
        count
      }
      fri {
        attended
        count
      }
      sat {
        attended
        count
      }
      sun {
        attended
        count
      }
    }
    currentMonthAttendance {
      id
      checkIn
      checkOut
      validation {
        status
      }
      meeting {
        name
        address {
          city
          state
        }
      }
    }
    reqMeetings
    reqMeetingsFreq
    durationDays
    probationId
    attendanceReminder
    attendanceReminderDays
    latestAttendanceActivity {
      ...AttendanceNoMeetingDetail
    }
    meetingsRequiredPerWeek
    createdAt
    managerId
  }
  ${AttendanceNoMeetingFragment}
`;

const ManagerDetailFragment = gql`
  fragment ManagerDetail on Manager {
    id
    email
    firstName
    lastName
    role
    phone
    photoUrl
    role
    clientInvites {
      id
    }
    organization {
      id
      name
      logoUrl
      meetings {
        id
        name
        address {
          street
          city
          state
          postalCode
        }
        allowsVirtual
        weekDay
        location {
          lat
          lng
        }
        startTime
        endTime
        type
      }
    }
    clients {
      ...UserDetail
    }
    createdAt
  }
  ${UserDetailFragment}
`;

const AdminDetailFragment = gql`
  fragment AdminDetail on Admin {
    id
    email
    firstName
    lastName
    role
    phone
    photoUrl
    createdAt
  }
`;

const ClientMonthlyCountFragment = gql`
  fragment MonthlyCountDetail on MonthlyAttendanceCount {
    meetingYear
    meetingMonth
    meetingCount
  }
`;

const ManagerNoteFragment = gql`
  fragment ManagerNoteDetail on ManagerNote {
    note
    createdAt
  }
`;

const AttendanceFragment = gql`
  fragment AttendanceDetail on Attendance {
    id
    checkIn
    checkOut
    status
    meeting {
      name
      location {
        lat
        lng
      }
      address {
        city
        state
      }
    }
    notes
    rating
    meetingStartTime
    meetingDurationMins
    checkInBatteryLevel
    isVirtual
    checkInVerification {
      lat
      lng
      biometricVerificationType
      photoUrl
    }
    checkOutVerification {
      lat
      lng
      biometricVerificationType
      photoUrl
    }
    validation {
      ...AttendanceValidationDetail
    }
  }
  ${AttendanceValidationFragment}
`;

export {
  AttendanceFragment,
  AttendanceValidationFragment,
  AttendanceNoMeetingFragment,
  UserDetailFragment,
  ManagerDetailFragment,
  AdminDetailFragment,
  ClientMonthlyCountFragment,
  ManagerNoteFragment
};
