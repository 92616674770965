import React from "react";
import { Col, Card, Avatar, Typography, Space, Divider, Button } from "antd";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import {
  MessageOutlined,
  FolderOutlined,
  ProfileOutlined,
  SafetyCertificateOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import classes from "../clientDetailPage.module.scss";

const { Text, Title } = Typography;

const ClientDetailSidebar = ({ client, manager, withActions }) => {
  return (
    <Col xl={6} lg={8}>
      <Card bodyStyle={{ padding: "1rem", textAlign: "center" }}>
        <Avatar
          style={{ marginTop: 24 }}
          size={86}
          src={client.photoUrl}
          alt="User Avatar"
        />
        <Title
          style={{ marginTop: 24 }}
          level={4}
        >{`${client.firstName} ${client.lastName}`}</Title>
        <Space direction="vertical">
          <Text style={{ wordBreak: "break-word" }}>{client.email}</Text>
          <Text>{client.phone}</Text>
        </Space>
        <Divider />
        <Space direction="vertical">
          {client.organization && (
            <Text strong>{client.organization.name}</Text>
          )}
          <Text>Since {moment(client.createdAt).format("MMMM Do YYYY")}</Text>
        </Space>
        <Divider />
        <Space direction="vertical">
          <Text strong>Supervisor</Text>
          <Text>{`${manager.firstName} ${manager.lastName}`}</Text>
        </Space>
        {withActions && (
          <>
            <Divider />
            <Space direction="vertical" style={{ width: "80%" }}>
              <Link to={`/messaging?clientId=${client.id}`}>
                <Button
                  className={classes.btnIconLeft}
                  type="primary"
                  shape="round"
                  title="Text chat"
                  icon={<MessageOutlined />}
                  size="large"
                  block
                >
                  Text chat
                </Button>
              </Link>
              {/* <Button
                className={classes.btnIconLeft}
                type="primary"
                shape="round"
                title="Video chat"
                icon={<VideoCameraOutlined />}
                size="large"
                block
              >
                Video chat
              </Button> */}
              <Link to={`/client/${client.id}/attendance`}>
                <Button
                  className={classes.btnIconLeft}
                  shape="round"
                  title="View Attendace"
                  icon={<SafetyCertificateOutlined />}
                  size="large"
                  block
                >
                  View Attendance
                </Button>
              </Link>
              <Link to={`/client/${client.id}/generate-report`}>
                <Button
                  className={classes.btnIconLeft}
                  shape="round"
                  title="Generate report"
                  icon={<FolderOutlined />}
                  size="large"
                  block
                >
                  Generate report
                </Button>
              </Link>
              <Link to={`/client/${client.id}/info`}>
                <Button
                  className={classes.btnIconLeft}
                  shape="round"
                  title="Edit client info"
                  icon={<ProfileOutlined />}
                  size="large"
                  block
                >
                  Edit client info
                </Button>
              </Link>
            </Space>
          </>
        )}
      </Card>
    </Col>
  );
};

ClientDetailSidebar.propTypes = {
  client: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  withActions: PropTypes.bool
};

export { ClientDetailSidebar };
