import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, PageHeader, Result } from "antd";
import { EnterOutlined, MessageTwoTone } from "@ant-design/icons";

import { CLIENT_EVENTS } from "core/constants/messaging";
import { Centered } from "components/util/Centered";
import { Spinner } from "components/util/Spinner";
import { formatPhoneNumber } from "core/services/format";
import { MessageBubble } from "./MessageBubble";

import styles from "./conversation.module.scss";

class Conversation extends Component {
  state = {
    newMessage: "",
    conversationProxy: this.props.conversationProxy,
    messages: [],
    loadingState: "initializing",
    boundConversations: new Set()
  };

  loadMessagesFor = thisConversation => {
    if (this.state.conversationProxy === thisConversation) {
      thisConversation
        .getMessages()
        .then(messagePaginator => {
          if (this.state.conversationProxy === thisConversation) {
            this.setState({
              messages: messagePaginator.items,
              loadingState: "ready"
            });
          }
        })
        .catch(err => {
          console.error("Couldn't fetch messages", err);
          this.setState({ loadingState: "failed" });
        });
    }
  };

  componentDidMount = () => {
    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on(CLIENT_EVENTS.MESSAGE_ADDED, m =>
          this.messageAdded(m, newConversation)
        );
        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            newConversation
          ])
        });
      }
    }
  };

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on(CLIENT_EVENTS.MESSAGE_ADDED, m =>
          this.messageAdded(m, newConversation)
        );
        this.setState({
          boundConversations: new Set([
            ...this.state.boundConversations,
            newConversation
          ])
        });
      }
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic =
      oldState.loadingState === "initializing" ||
      oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return {
        loadingState: "loading messages",
        conversationProxy: newProps.conversationProxy
      };
    } else {
      return null;
    }
  }

  messageAdded = (message, targetConversation) => {
    if (targetConversation === this.state.conversationProxy)
      this.setState((prevState, props) => ({
        messages: [...prevState.messages, message]
      }));
  };

  onMessageChanged = event => {
    this.setState({ newMessage: event.target.value });
  };

  sendMessage = () => {
    if (this.state.newMessage.trim()) {
      const message = this.state.newMessage;
      this.setState({ newMessage: "" });
      this.state.conversationProxy.sendMessage(message);
    }
  };

  render = () => {
    const isReady = this.state.loadingState === "ready";
    const isError = this.state.loadingState === "error";
    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.chatHeader}>
            <PageHeader
              avatar={{ src: this.props.client.photoUrl }}
              title={`${this.props.client.firstName} ${this.props.client.lastName}`}
              subTitle={formatPhoneNumber(this.props.client.phone)}
            />
          </div>
          <div className={styles.messagesContainer}>
            {!isReady && <Spinner />}
            {isError && (
              <Centered>
                <Result
                  status="error"
                  title="Error loading messages, please reload and try again"
                />
              </Centered>
            )}
            {isReady && !this.state.messages.length && (
              <Centered>
                <Result
                  icon={<MessageTwoTone />}
                  title="Empty conversation, type below to get started"
                />
              </Centered>
            )}
            {isReady &&
              !!this.state.messages.length &&
              this.state.messages.map(m => {
                const direction =
                  m.state.author === this.props.myIdentity ? "out" : "in";
                return (
                  <MessageBubble
                    key={m.sid}
                    message={m}
                    direction={direction}
                  />
                );
              })}
          </div>
          <div className={styles.messageInputForm}>
            <Form onFinish={this.sendMessage}>
              <Input.Group
                size="large"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <Input
                  style={{ flex: 1 }}
                  placeholder="Type your message here..."
                  type="text"
                  name="message"
                  autoComplete="off"
                  disabled={this.state.loadingState !== "ready"}
                  onChange={this.onMessageChanged}
                  value={this.state.newMessage}
                />
                <Button
                  size="large"
                  icon={<EnterOutlined />}
                  htmlType="submit"
                />
              </Input.Group>
            </Form>
          </div>
        </div>
      </div>
    );
  };
}

Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  conversationProxy: PropTypes.object.isRequired
};

export { Conversation };
