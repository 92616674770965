import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { AuthLayout } from "components/layout/AuthLayout/AuthLayout";
import { ManagerLayout } from "components/layout/ManagerLayout/ManagerLayout";
import { LoginPage } from "components/auth/login/LoginPage";
import { ForgotPasswordPage } from "components/auth/password/ForgotPasswordPage";
import { DashboardPage } from "components/dashboard/DashboardPage";
import { ClientDetailPage } from "components/client/ClientDetailPage";
import { AttendancePage } from "components/client/AttendancePage";
import { ManagerProfilePage } from "components/profile/ManagerProfilePage";
import { InviteClientPage } from "components/client/InviteClientPage";
import { PendingInvitesPage } from "components/client/PendingInvitesPage";
import { OrganizationMeetingsPage } from "components/meetings/OrganizationMeetingsPage";
import { ConfigureClientReportPage } from "components/client/ConfigureClientReportPage";
import { EditClientInfoPage } from "components/client/EditClientInfoPage";
import { CreateMeetingPage } from "components/meetings/CreateMeetingPage";
import { EditMeetingPage } from "components/meetings/EditMeetingPage";
import { ClientInvitePage } from "components/auth/clientInvite/ClientInvitePage";
import { MessagingCenter } from "components/messaging/MessagingCenter";

const Routes = () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Redirect from="/" exact to="/overview" />
          {/** Public */}
          <AuthLayout path="/login" component={LoginPage} />
          <AuthLayout path="/forgotpass" component={ForgotPasswordPage} />
          <AuthLayout path="/invite/:token" component={ClientInvitePage} />
          {/** Authenticated */}
          <ManagerLayout path="/profile" exact component={ManagerProfilePage} />
          <ManagerLayout path="/overview" exact component={DashboardPage} />
          <ManagerLayout
            exact
            path="/client/invite"
            component={InviteClientPage}
          />
          <ManagerLayout
            exact
            path="/pending-invites"
            component={PendingInvitesPage}
          />
          <ManagerLayout exact path="/messaging" component={MessagingCenter} />
          <ManagerLayout
            exact
            path="/client/:id"
            component={ClientDetailPage}
          />
          <ManagerLayout
            exact
            path="/client/:id/info"
            component={EditClientInfoPage}
          />
          <ManagerLayout
            path="/client/:id/attendance"
            component={AttendancePage}
          />
          <ManagerLayout
            path="/client/:id/generate-report"
            component={ConfigureClientReportPage}
          />
          <ManagerLayout
            exact
            path="/org/meetings"
            component={OrganizationMeetingsPage}
          />
          <ManagerLayout
            exact
            path="/org/meetings/create"
            component={CreateMeetingPage}
          />
          <ManagerLayout
            exact
            path="/org/meetings/:id/edit"
            component={EditMeetingPage}
          />
        </Switch>
      </QueryParamProvider>
    </Router>
  );
};

export { Routes };
