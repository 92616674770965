import React from "react";
import { DatePicker, Select, Space, Tag } from "antd";
import PropTypes from "prop-types";
import * as moment from "moment-timezone";

import {
  ATTENDANCE_STATUS,
  ATTENDANCE_STATUS_TAG_COLOR
} from "core/services/attendance";
import { getValidationStatuses } from "./attendanceTable.utils";

const { RangePicker } = DatePicker;
const { Option } = Select;

const AttendanceTableControls = ({
  statuses,
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate
}) => {
  const [statusValue, setStatus] = statuses;
  const [startDateValue, setStartDate] = startDate;
  const [endDateValue, setEndDate] = endDate;

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate(defaultStartDate);
      setEndDate(defaultEndDate);
    }
  };

  const onStatusChange = values => {
    setStatus(values);
  };

  let validationStatuses = getValidationStatuses(statusValue);

  return (
    <div style={{ marginBottom: 25 }}>
      <Space>
        <RangePicker
          defaultValue={[moment(startDateValue), moment(endDateValue)]}
          value={[moment(startDateValue), moment(endDateValue)]}
          format={"MM/DD/YYYY"}
          onChange={onRangeChange}
        />
        <Select
          mode="multiple"
          style={{ width: "25rem" }}
          placeholder="Filter by status"
          value={validationStatuses}
          optionLabelProp="label"
          onChange={onStatusChange}
        >
          <Option value={ATTENDANCE_STATUS.PENDING} label="Pending">
            <Tag color={ATTENDANCE_STATUS_TAG_COLOR[ATTENDANCE_STATUS.PENDING]}>
              {ATTENDANCE_STATUS.PENDING}
            </Tag>
          </Option>
          <Option value={ATTENDANCE_STATUS.VERIFIED} label="Verified">
            <Tag
              color={ATTENDANCE_STATUS_TAG_COLOR[ATTENDANCE_STATUS.VERIFIED]}
            >
              {ATTENDANCE_STATUS.VERIFIED}
            </Tag>
          </Option>
          <Option value={ATTENDANCE_STATUS.UNVERIFIED} label="Unverified">
            <Tag
              color={ATTENDANCE_STATUS_TAG_COLOR[ATTENDANCE_STATUS.UNVERIFIED]}
            >
              {ATTENDANCE_STATUS.UNVERIFIED}
            </Tag>
          </Option>
        </Select>
      </Space>
    </div>
  );
};

AttendanceTableControls.propTypes = {
  defaultStartDate: PropTypes.string.isRequired,
  defaultEndDate: PropTypes.string.isRequired,
  startDate: PropTypes.array.isRequired,
  endDate: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired
};

export { AttendanceTableControls };
